export default defineNuxtRouteMiddleware(async () => {
  const { $individual, $partner, $partnerEmployee, $payianoEmployee } =
    useNuxtApp()

  const promises = []

  if (!$individual.loaded && !$individual.loading && $individual.getToken()) {
    promises.push($individual.loadProfileInfo(), $individual.loadEmployments())
  }

  if (
    !$partnerEmployee.loaded &&
    !$partnerEmployee.loading &&
    $partnerEmployee.getToken()
  ) {
    promises.push($partnerEmployee.loadProfileInfo())
    promises.push($partner.loadProfileInfo())
  }

  if (
    !$payianoEmployee.loaded &&
    !$payianoEmployee.loading &&
    $payianoEmployee.getToken()
  ) {
    promises.push($payianoEmployee.loadProfileInfo())
  }

  await Promise.allSettled(promises)
})
