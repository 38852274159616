import type { RouterConfig } from '@nuxt/schema'
import { getElementAbsolutePosition, hasValue } from '@payiano/ha-utils'

export default <RouterConfig>{
  scrollBehavior: (to) => {
    let isScrolled

    if (
      import.meta.browser &&
      to.path.startsWith('/api/rest/v1') &&
      hasValue(to.hash)
    ) {
      const hash = to.hash.replace(/^#/, '')

      const el = document.getElementById(hash)
      if (el) {
        isScrolled = true

        const { y } = getElementAbsolutePosition(el)

        window.scrollTo({
          top: y - 75,
          behavior: 'smooth'
        })
      }
    }

    if (!isScrolled) {
      window.scrollTo({
        top: 0,
        behavior: 'instant'
      })
    }
  }
}
