import { default as indexvtHjlD0Ea7Meta } from "/app/pages/api/rest/get-started/index.vue?macro=true";
import { default as indexWzxdYNefLmMeta } from "/app/pages/api/rest/reference/currency-codes/index.vue?macro=true";
import { default as indexSaC8sIRIWfMeta } from "/app/pages/api/rest/reference/datetime-formats/index.vue?macro=true";
import { default as indexdtrdgefJO8Meta } from "/app/pages/api/rest/reference/errors-handling/index.vue?macro=true";
import { default as indexWZM673Uo6mMeta } from "/app/pages/api/rest/reference/identify-resource/index.vue?macro=true";
import { default as indexa7P2Nx87dHMeta } from "/app/pages/api/rest/reference/including-resources/index.vue?macro=true";
import { default as indexJlczczGC0AMeta } from "/app/pages/api/rest/reference/pagination/index.vue?macro=true";
import { default as indexz007GCB5fPMeta } from "/app/pages/api/rest/reference/processing-fees-strategy/index.vue?macro=true";
import { default as indexEUpCQ5H81qMeta } from "/app/pages/api/rest/reference/tax-calculations/index.vue?macro=true";
import { default as indexf1ksU0hZIMMeta } from "/app/pages/api/rest/reference/test-pay-order/index.vue?macro=true";
import { default as indexd1rttHWrI5Meta } from "/app/pages/api/rest/reference/webhooks/build-signature/index.vue?macro=true";
import { default as indexkk4ExnHeHTMeta } from "/app/pages/api/rest/reference/webhooks/event-types/index.vue?macro=true";
import { default as indexCCl2KaTv5nMeta } from "/app/pages/api/rest/reference/webhooks/overview/index.vue?macro=true";
import { default as indexXVzqAo7QczMeta } from "/app/pages/api/rest/reference/webhooks/verify-signature/index.vue?macro=true";
import { default as index74vvd40ExvMeta } from "/app/pages/api/rest/v1/ally/basata/bills/inquiry/index.vue?macro=true";
import { default as index95JOcyUVimMeta } from "/app/pages/api/rest/v1/ally/basata/bills/pay/index.vue?macro=true";
import { default as indexsTtx7nbuJtMeta } from "/app/pages/api/rest/v1/ally/basata/bills/status/index.vue?macro=true";
import { default as indexc8G3McUG12Meta } from "/app/pages/api/rest/v1/company/app/whoami/index.vue?macro=true";
import { default as indexYyNTrzXtBzMeta } from "/app/pages/api/rest/v1/company/apps/create/index.vue?macro=true";
import { default as indexyO8ZV3twjLMeta } from "/app/pages/api/rest/v1/company/apps/list/index.vue?macro=true";
import { default as index6XQDBN4X64Meta } from "/app/pages/api/rest/v1/company/apps/reset-token/index.vue?macro=true";
import { default as indexCp2B9QSVOEMeta } from "/app/pages/api/rest/v1/company/apps/retrieve/index.vue?macro=true";
import { default as indexoelom24NlwMeta } from "/app/pages/api/rest/v1/company/apps/update/index.vue?macro=true";
import { default as indexeiXkcMkMFjMeta } from "/app/pages/api/rest/v1/company/autocomplete/apps/index.vue?macro=true";
import { default as indexNVkEYtvfttMeta } from "/app/pages/api/rest/v1/company/autocomplete/customer-tags/index.vue?macro=true";
import { default as indexW1U5nPs9K1Meta } from "/app/pages/api/rest/v1/company/autocomplete/customers/index.vue?macro=true";
import { default as index6dxGLiyAySMeta } from "/app/pages/api/rest/v1/company/autocomplete/payment-methods/index.vue?macro=true";
import { default as indexkul1rExoanMeta } from "/app/pages/api/rest/v1/company/autocomplete/roles/index.vue?macro=true";
import { default as indexavdxwkpmTMMeta } from "/app/pages/api/rest/v1/company/autocomplete/taxes/index.vue?macro=true";
import { default as indexj3D06vviO5Meta } from "/app/pages/api/rest/v1/company/autocomplete/webhook-event-types/index.vue?macro=true";
import { default as indexwdYjcMvZMIMeta } from "/app/pages/api/rest/v1/company/autocomplete/webhook-urls/index.vue?macro=true";
import { default as indexSHdL8v4RPVMeta } from "/app/pages/api/rest/v1/company/company/approval/request/index.vue?macro=true";
import { default as indexpoe26gb8ZnMeta } from "/app/pages/api/rest/v1/company/company/approval/retrieve/index.vue?macro=true";
import { default as indexySuvHZkIcnMeta } from "/app/pages/api/rest/v1/company/company/avatar/delete/index.vue?macro=true";
import { default as indexYgZJ0EF3TjMeta } from "/app/pages/api/rest/v1/company/company/avatar/upload/index.vue?macro=true";
import { default as indexNmbxMceK8bMeta } from "/app/pages/api/rest/v1/company/company/business/retrieve/index.vue?macro=true";
import { default as indexZdZHfIN2e3Meta } from "/app/pages/api/rest/v1/company/company/business/update/index.vue?macro=true";
import { default as indexNkvDpy7YaRMeta } from "/app/pages/api/rest/v1/company/company/company/index.vue?macro=true";
import { default as indexCL9kYo6MwMMeta } from "/app/pages/api/rest/v1/company/company/documents/list/index.vue?macro=true";
import { default as indexW0r9R94z9hMeta } from "/app/pages/api/rest/v1/company/company/documents/upload/index.vue?macro=true";
import { default as indexnfFcvpvkSeMeta } from "/app/pages/api/rest/v1/company/company/info/retrieve/index.vue?macro=true";
import { default as indexuQHJls4RyEMeta } from "/app/pages/api/rest/v1/company/company/info/update/index.vue?macro=true";
import { default as indexD80DWF9T20Meta } from "/app/pages/api/rest/v1/company/company/owners/create/index.vue?macro=true";
import { default as indexXjrrUB9LYDMeta } from "/app/pages/api/rest/v1/company/company/owners/delete/index.vue?macro=true";
import { default as indexecUtvY8yrEMeta } from "/app/pages/api/rest/v1/company/company/owners/documents/list/index.vue?macro=true";
import { default as indexFWHdaue7qkMeta } from "/app/pages/api/rest/v1/company/company/owners/documents/upload/index.vue?macro=true";
import { default as indexD6wkO2AZV3Meta } from "/app/pages/api/rest/v1/company/company/owners/list/index.vue?macro=true";
import { default as indexLBP26pjIvqMeta } from "/app/pages/api/rest/v1/company/company/owners/restore/index.vue?macro=true";
import { default as indexWtGWkoZ714Meta } from "/app/pages/api/rest/v1/company/company/owners/retrieve/index.vue?macro=true";
import { default as index23lNpLSwBEMeta } from "/app/pages/api/rest/v1/company/company/owners/update/index.vue?macro=true";
import { default as indexezBB05sjveMeta } from "/app/pages/api/rest/v1/company/company/username/check/index.vue?macro=true";
import { default as index5wBlKmW92wMeta } from "/app/pages/api/rest/v1/company/company/username/retrieve/index.vue?macro=true";
import { default as indexYFB5WLUx0aMeta } from "/app/pages/api/rest/v1/company/company/username/update/index.vue?macro=true";
import { default as indexyErrH7iuGTMeta } from "/app/pages/api/rest/v1/company/customers/addresses/delete/index.vue?macro=true";
import { default as indexA9JhLZ3FhMMeta } from "/app/pages/api/rest/v1/company/customers/addresses/list/index.vue?macro=true";
import { default as indexLRQQhAIkuTMeta } from "/app/pages/api/rest/v1/company/customers/addresses/restore/index.vue?macro=true";
import { default as indexekuXW510OaMeta } from "/app/pages/api/rest/v1/company/customers/addresses/retrieve/index.vue?macro=true";
import { default as indexskqBWlV1HRMeta } from "/app/pages/api/rest/v1/company/customers/addresses/update/index.vue?macro=true";
import { default as index9eVGYVSSYPMeta } from "/app/pages/api/rest/v1/company/customers/create/index.vue?macro=true";
import { default as indexcQKpsk1JPYMeta } from "/app/pages/api/rest/v1/company/customers/customer-addresses/create/index.vue?macro=true";
import { default as indexXSYEok60VvMeta } from "/app/pages/api/rest/v1/company/customers/customer-addresses/list/index.vue?macro=true";
import { default as indexZNRxPE75IHMeta } from "/app/pages/api/rest/v1/company/customers/list/index.vue?macro=true";
import { default as index2o1pTzd6ckMeta } from "/app/pages/api/rest/v1/company/customers/retrieve/index.vue?macro=true";
import { default as indexdhZHhkr5ztMeta } from "/app/pages/api/rest/v1/company/customers/tags/create/index.vue?macro=true";
import { default as indexJXlHKCcoQkMeta } from "/app/pages/api/rest/v1/company/customers/tags/delete/index.vue?macro=true";
import { default as index7tvQaSYsF5Meta } from "/app/pages/api/rest/v1/company/customers/tags/list/index.vue?macro=true";
import { default as index7xQVSKTZbjMeta } from "/app/pages/api/rest/v1/company/customers/tags/restore/index.vue?macro=true";
import { default as indexB8fbbJGJ6wMeta } from "/app/pages/api/rest/v1/company/customers/tags/retrieve/index.vue?macro=true";
import { default as indexOMLlU3nEvaMeta } from "/app/pages/api/rest/v1/company/customers/tags/update/index.vue?macro=true";
import { default as indexsFBAHOCIyQMeta } from "/app/pages/api/rest/v1/company/customers/update/index.vue?macro=true";
import { default as indexNGRB3eDBdiMeta } from "/app/pages/api/rest/v1/company/discounts/create/index.vue?macro=true";
import { default as indexgNv8PZyh43Meta } from "/app/pages/api/rest/v1/company/discounts/list/index.vue?macro=true";
import { default as indexvbTuVcFhY8Meta } from "/app/pages/api/rest/v1/company/discounts/retrieve/index.vue?macro=true";
import { default as indexGPpqcvMY06Meta } from "/app/pages/api/rest/v1/company/discounts/update/index.vue?macro=true";
import { default as indexbtTq9Jh35kMeta } from "/app/pages/api/rest/v1/company/employee/logout/index.vue?macro=true";
import { default as indexhzRfcMOAA1Meta } from "/app/pages/api/rest/v1/company/employee/notifications/delete/index.vue?macro=true";
import { default as indexqXmlYnyGsZMeta } from "/app/pages/api/rest/v1/company/employee/notifications/list/index.vue?macro=true";
import { default as index7K1i8SzhTXMeta } from "/app/pages/api/rest/v1/company/employee/notifications/mark-all-as-read/index.vue?macro=true";
import { default as indexRbPFMU9vABMeta } from "/app/pages/api/rest/v1/company/employee/notifications/mark/index.vue?macro=true";
import { default as index2PttIDFincMeta } from "/app/pages/api/rest/v1/company/employee/notifications/retrieve/index.vue?macro=true";
import { default as indexEWyAbzxSTqMeta } from "/app/pages/api/rest/v1/company/employee/notifications/unread-count/index.vue?macro=true";
import { default as index3m6EX3vBDkMeta } from "/app/pages/api/rest/v1/company/employee/whoami/index.vue?macro=true";
import { default as indexXX14BoGLTtMeta } from "/app/pages/api/rest/v1/company/employees/activation/index.vue?macro=true";
import { default as indexBHIKjyBZRLMeta } from "/app/pages/api/rest/v1/company/employees/cancel-invitation/index.vue?macro=true";
import { default as indexfjZUMlHBTtMeta } from "/app/pages/api/rest/v1/company/employees/delete/index.vue?macro=true";
import { default as indextkZzbWsyddMeta } from "/app/pages/api/rest/v1/company/employees/invite/index.vue?macro=true";
import { default as indexiXOsobJIYxMeta } from "/app/pages/api/rest/v1/company/employees/list/index.vue?macro=true";
import { default as indexBGRGs2yqBGMeta } from "/app/pages/api/rest/v1/company/employees/resend-invitation/index.vue?macro=true";
import { default as indexkoUxSpJOJnMeta } from "/app/pages/api/rest/v1/company/employees/retrieve/index.vue?macro=true";
import { default as indexVoSyvRKuYPMeta } from "/app/pages/api/rest/v1/company/employees/update/index.vue?macro=true";
import { default as indexrTvk66ERczMeta } from "/app/pages/api/rest/v1/company/online-payments/balances/list/index.vue?macro=true";
import { default as index68nN9xBuBzMeta } from "/app/pages/api/rest/v1/company/online-payments/balances/retrieve/index.vue?macro=true";
import { default as indexM6xMECrzHLMeta } from "/app/pages/api/rest/v1/company/online-payments/checkouts/list/index.vue?macro=true";
import { default as indexvsdbtYWMKMMeta } from "/app/pages/api/rest/v1/company/online-payments/checkouts/retrieve/index.vue?macro=true";
import { default as indexGVJUUO2KldMeta } from "/app/pages/api/rest/v1/company/online-payments/payment-links/cancel/index.vue?macro=true";
import { default as indexN5V5GhG2R6Meta } from "/app/pages/api/rest/v1/company/online-payments/payment-links/create/index.vue?macro=true";
import { default as indexNJqM1vIjK1Meta } from "/app/pages/api/rest/v1/company/online-payments/payment-links/list/index.vue?macro=true";
import { default as index2bZ3rh5MKJMeta } from "/app/pages/api/rest/v1/company/online-payments/payment-links/retrieve-checkout/index.vue?macro=true";
import { default as indexDg4k4NHocfMeta } from "/app/pages/api/rest/v1/company/online-payments/payment-links/retrieve/index.vue?macro=true";
import { default as index674kKpnx8wMeta } from "/app/pages/api/rest/v1/company/online-payments/payment-links/update/index.vue?macro=true";
import { default as indexnodGoCkRFmMeta } from "/app/pages/api/rest/v1/company/online-payments/payment-methods/list/index.vue?macro=true";
import { default as indexQSejgMVhtvMeta } from "/app/pages/api/rest/v1/company/online-payments/payment-methods/lists-currencies/index.vue?macro=true";
import { default as indexNUkHyM9rq3Meta } from "/app/pages/api/rest/v1/company/online-payments/payment-methods/retrieve/index.vue?macro=true";
import { default as index5XAQHhIRwUMeta } from "/app/pages/api/rest/v1/company/online-payments/payment-methods/update/index.vue?macro=true";
import { default as indexBiGBzFSldwMeta } from "/app/pages/api/rest/v1/company/online-payments/transactions/list/index.vue?macro=true";
import { default as index96k8FCZFnsMeta } from "/app/pages/api/rest/v1/company/online-payments/transactions/retrieve/index.vue?macro=true";
import { default as indexw2MmvB00BxMeta } from "/app/pages/api/rest/v1/company/permissions/list/index.vue?macro=true";
import { default as indexFgLeujHcs3Meta } from "/app/pages/api/rest/v1/company/request-logs/list/index.vue?macro=true";
import { default as indexRkTHoxb3CoMeta } from "/app/pages/api/rest/v1/company/request-logs/retrieve/index.vue?macro=true";
import { default as indexGQvAH9ZBj3Meta } from "/app/pages/api/rest/v1/company/roles/create/index.vue?macro=true";
import { default as indexNZWH4NRHsgMeta } from "/app/pages/api/rest/v1/company/roles/list/index.vue?macro=true";
import { default as indexjAREkE75MIMeta } from "/app/pages/api/rest/v1/company/roles/retrieve/index.vue?macro=true";
import { default as indexGQe8yNxPrvMeta } from "/app/pages/api/rest/v1/company/roles/update/index.vue?macro=true";
import { default as indexsLFbStcUTTMeta } from "/app/pages/api/rest/v1/company/store/brands/create/index.vue?macro=true";
import { default as indexpv5GGU506iMeta } from "/app/pages/api/rest/v1/company/store/brands/delete/index.vue?macro=true";
import { default as indexP3GPqAlOeXMeta } from "/app/pages/api/rest/v1/company/store/brands/list/index.vue?macro=true";
import { default as indexqLVPwoHfXpMeta } from "/app/pages/api/rest/v1/company/store/brands/restore/index.vue?macro=true";
import { default as indexjJLmAe8h7UMeta } from "/app/pages/api/rest/v1/company/store/brands/retrieve/index.vue?macro=true";
import { default as indexCLcnaLfIOqMeta } from "/app/pages/api/rest/v1/company/store/brands/update/index.vue?macro=true";
import { default as indexKUneo5iG1zMeta } from "/app/pages/api/rest/v1/company/store/categories/create/index.vue?macro=true";
import { default as index2oCEEGTr7AMeta } from "/app/pages/api/rest/v1/company/store/categories/delete/index.vue?macro=true";
import { default as indexUXFdobhtpQMeta } from "/app/pages/api/rest/v1/company/store/categories/list/index.vue?macro=true";
import { default as indexdKtAEEvlu5Meta } from "/app/pages/api/rest/v1/company/store/categories/restore/index.vue?macro=true";
import { default as indexwU8uhrY1VqMeta } from "/app/pages/api/rest/v1/company/store/categories/retrieve/index.vue?macro=true";
import { default as indexsIG3PAtx3DMeta } from "/app/pages/api/rest/v1/company/store/categories/update/index.vue?macro=true";
import { default as indexmuijLcXQo7Meta } from "/app/pages/api/rest/v1/company/support/comments/create/index.vue?macro=true";
import { default as indexFwF85zsK7GMeta } from "/app/pages/api/rest/v1/company/support/comments/list/index.vue?macro=true";
import { default as indexZZjuHSNDcMMeta } from "/app/pages/api/rest/v1/company/support/comments/retrieve/index.vue?macro=true";
import { default as indexKxXXBjrrT3Meta } from "/app/pages/api/rest/v1/company/support/tickets/close/index.vue?macro=true";
import { default as indexNNkXxSM8KFMeta } from "/app/pages/api/rest/v1/company/support/tickets/create/index.vue?macro=true";
import { default as indexu4edU2CzwMMeta } from "/app/pages/api/rest/v1/company/support/tickets/list/index.vue?macro=true";
import { default as index8V2yMAPQjEMeta } from "/app/pages/api/rest/v1/company/support/tickets/retrieve/index.vue?macro=true";
import { default as indexJ3dHsMwxlqMeta } from "/app/pages/api/rest/v1/company/taxes/create/index.vue?macro=true";
import { default as index2iHiOyWIlHMeta } from "/app/pages/api/rest/v1/company/taxes/list/index.vue?macro=true";
import { default as indexw7XagjMzSHMeta } from "/app/pages/api/rest/v1/company/taxes/retrieve/index.vue?macro=true";
import { default as indexwiGTl2PZYXMeta } from "/app/pages/api/rest/v1/company/taxes/update/index.vue?macro=true";
import { default as indexBclk7a18bkMeta } from "/app/pages/api/rest/v1/company/webhooks/signatures/verify/index.vue?macro=true";
import { default as index7RxQ1XwKMtMeta } from "/app/pages/api/rest/v1/company/webhooks/webhook-event-attempts/list/index.vue?macro=true";
import { default as indexSIsyKU2phlMeta } from "/app/pages/api/rest/v1/company/webhooks/webhook-event-attempts/retrieve/index.vue?macro=true";
import { default as indexuB41IGBHeDMeta } from "/app/pages/api/rest/v1/company/webhooks/webhook-event-types/list/index.vue?macro=true";
import { default as indexio7KgaEhjCMeta } from "/app/pages/api/rest/v1/company/webhooks/webhook-events/attempts/list/index.vue?macro=true";
import { default as indexofc0URyJwkMeta } from "/app/pages/api/rest/v1/company/webhooks/webhook-events/attempts/send/index.vue?macro=true";
import { default as indexwV2WyYQ809Meta } from "/app/pages/api/rest/v1/company/webhooks/webhook-events/delete/index.vue?macro=true";
import { default as indexHVRXBxWRXAMeta } from "/app/pages/api/rest/v1/company/webhooks/webhook-events/list/index.vue?macro=true";
import { default as indexMlxORgCWF0Meta } from "/app/pages/api/rest/v1/company/webhooks/webhook-events/restore/index.vue?macro=true";
import { default as indexPFRWSbPZouMeta } from "/app/pages/api/rest/v1/company/webhooks/webhook-events/retrieve/index.vue?macro=true";
import { default as indexORrSwTSONyMeta } from "/app/pages/api/rest/v1/company/webhooks/webhook-urls/create/index.vue?macro=true";
import { default as index8XpHUgFvlOMeta } from "/app/pages/api/rest/v1/company/webhooks/webhook-urls/delete/index.vue?macro=true";
import { default as indexkv9tVs6JcNMeta } from "/app/pages/api/rest/v1/company/webhooks/webhook-urls/list/index.vue?macro=true";
import { default as indexZjxvNztEywMeta } from "/app/pages/api/rest/v1/company/webhooks/webhook-urls/reset-signature/index.vue?macro=true";
import { default as indexz4n2fhUb3fMeta } from "/app/pages/api/rest/v1/company/webhooks/webhook-urls/restore/index.vue?macro=true";
import { default as indexd0zvHFUyOhMeta } from "/app/pages/api/rest/v1/company/webhooks/webhook-urls/retrieve/index.vue?macro=true";
import { default as indexhwrpLXoOXUMeta } from "/app/pages/api/rest/v1/company/webhooks/webhook-urls/update/index.vue?macro=true";
import { default as indexor38VDvidCMeta } from "/app/pages/api/rest/v1/individual/authentication/login/index.vue?macro=true";
import { default as index7pvMSLNPmOMeta } from "/app/pages/api/rest/v1/individual/authentication/logout/index.vue?macro=true";
import { default as indexvzKxb084OxMeta } from "/app/pages/api/rest/v1/individual/authentication/process-social-login/index.vue?macro=true";
import { default as indexptzQizHBt7Meta } from "/app/pages/api/rest/v1/individual/authentication/register/index.vue?macro=true";
import { default as indexW2w830sMmmMeta } from "/app/pages/api/rest/v1/individual/authentication/request-social-login/index.vue?macro=true";
import { default as indexzQFKpkltKkMeta } from "/app/pages/api/rest/v1/individual/companies/create/index.vue?macro=true";
import { default as indexidyiAWMxFeMeta } from "/app/pages/api/rest/v1/individual/companies/pay-on-behalf/index.vue?macro=true";
import { default as indexn6l9holkZgMeta } from "/app/pages/api/rest/v1/individual/employment-invitation/accept/index.vue?macro=true";
import { default as indexKtrPTb8MPwMeta } from "/app/pages/api/rest/v1/individual/employment-invitation/retrieve-info/index.vue?macro=true";
import { default as indexn6ol2s6pfgMeta } from "/app/pages/api/rest/v1/individual/employments/list/index.vue?macro=true";
import { default as index1M8DGtQz8KMeta } from "/app/pages/api/rest/v1/individual/employments/retrieve/index.vue?macro=true";
import { default as indext94wjvEITDMeta } from "/app/pages/api/rest/v1/individual/employments/token/index.vue?macro=true";
import { default as indexoGBGfDi8r9Meta } from "/app/pages/api/rest/v1/individual/notifications/delete/index.vue?macro=true";
import { default as indexFXjcXOxd0AMeta } from "/app/pages/api/rest/v1/individual/notifications/list/index.vue?macro=true";
import { default as index6dphKidcqYMeta } from "/app/pages/api/rest/v1/individual/notifications/mark-all-as-read/index.vue?macro=true";
import { default as indexjQDg3QGjU3Meta } from "/app/pages/api/rest/v1/individual/notifications/mark/index.vue?macro=true";
import { default as indexsDF9gXjvSfMeta } from "/app/pages/api/rest/v1/individual/notifications/retrieve/index.vue?macro=true";
import { default as indexIGr70wmFTuMeta } from "/app/pages/api/rest/v1/individual/notifications/unread-count/index.vue?macro=true";
import { default as indexCUJ2aJ3qYKMeta } from "/app/pages/api/rest/v1/individual/password-reset/process-reset/index.vue?macro=true";
import { default as indexiYPPb88mBjMeta } from "/app/pages/api/rest/v1/individual/password-reset/request-info/index.vue?macro=true";
import { default as indexDjwOMLNes2Meta } from "/app/pages/api/rest/v1/individual/password-reset/request-reset/index.vue?macro=true";
import { default as indexsk3Dg5nlMaMeta } from "/app/pages/api/rest/v1/individual/profile/approval/request/index.vue?macro=true";
import { default as indexbLX217l9bHMeta } from "/app/pages/api/rest/v1/individual/profile/approval/retrieve/index.vue?macro=true";
import { default as indexgGOwHixoVbMeta } from "/app/pages/api/rest/v1/individual/profile/avatar/delete/index.vue?macro=true";
import { default as indexyQMSu5gsK2Meta } from "/app/pages/api/rest/v1/individual/profile/avatar/upload/index.vue?macro=true";
import { default as indexqJk4Xb0AoVMeta } from "/app/pages/api/rest/v1/individual/profile/documents/list/index.vue?macro=true";
import { default as indexj0on0HWaL5Meta } from "/app/pages/api/rest/v1/individual/profile/documents/upload/index.vue?macro=true";
import { default as indexL7wkpXaD4ZMeta } from "/app/pages/api/rest/v1/individual/profile/password-change/index.vue?macro=true";
import { default as index4ITA137TNuMeta } from "/app/pages/api/rest/v1/individual/profile/phonenumber/send-otp/index.vue?macro=true";
import { default as indexp2nSjHpQiXMeta } from "/app/pages/api/rest/v1/individual/profile/phonenumber/verify-otp/index.vue?macro=true";
import { default as index3gPYtiAUi7Meta } from "/app/pages/api/rest/v1/individual/profile/update/index.vue?macro=true";
import { default as indexfkA0Sjz8wzMeta } from "/app/pages/api/rest/v1/individual/profile/verify-email/index.vue?macro=true";
import { default as indexnW9U6sa9c6Meta } from "/app/pages/api/rest/v1/individual/support/comments/create/index.vue?macro=true";
import { default as indexkT57bsCvhaMeta } from "/app/pages/api/rest/v1/individual/support/comments/list/index.vue?macro=true";
import { default as indexQ3PYeBstKnMeta } from "/app/pages/api/rest/v1/individual/support/comments/retrieve/index.vue?macro=true";
import { default as indexNzLLyURybFMeta } from "/app/pages/api/rest/v1/individual/support/tickets/close/index.vue?macro=true";
import { default as indexWPIE2HRKFkMeta } from "/app/pages/api/rest/v1/individual/support/tickets/create/index.vue?macro=true";
import { default as indexfo0ngJMJMlMeta } from "/app/pages/api/rest/v1/individual/support/tickets/list/index.vue?macro=true";
import { default as indexazF8BMsn5DMeta } from "/app/pages/api/rest/v1/individual/support/tickets/retrieve/index.vue?macro=true";
import { default as index8nWKzgkdiOMeta } from "/app/pages/api/rest/v1/individual/twofa/activate/index.vue?macro=true";
import { default as indexiLNTDoUaPsMeta } from "/app/pages/api/rest/v1/individual/twofa/generate/index.vue?macro=true";
import { default as indexkVEqiOz9LXMeta } from "/app/pages/api/rest/v1/individual/twofa/reset/index.vue?macro=true";
import { default as index5Od8zMztToMeta } from "/app/pages/api/rest/v1/individual/twofa/retrieve/index.vue?macro=true";
import { default as indexZL5kbxK0yXMeta } from "/app/pages/api/rest/v1/individual/username/check/index.vue?macro=true";
import { default as indexl5e0QcM0q9Meta } from "/app/pages/api/rest/v1/individual/username/retrieve/index.vue?macro=true";
import { default as indexixmNmn4bqaMeta } from "/app/pages/api/rest/v1/individual/username/update/index.vue?macro=true";
import { default as indexD2DzDA9eNBMeta } from "/app/pages/api/rest/v1/individual/verify-user-email/index.vue?macro=true";
import { default as indexQcGnejFgEvMeta } from "/app/pages/api/rest/v1/individual/whoami/index.vue?macro=true";
import { default as index5O2SOeWPttMeta } from "/app/pages/api/rest/v1/partner/app/whoami/index.vue?macro=true";
import { default as indexuvImvn5TQUMeta } from "/app/pages/api/rest/v1/partner/apps/create/index.vue?macro=true";
import { default as indexJJ8Uom0tJaMeta } from "/app/pages/api/rest/v1/partner/apps/list/index.vue?macro=true";
import { default as index2zmMoYnK1xMeta } from "/app/pages/api/rest/v1/partner/apps/reset-token/index.vue?macro=true";
import { default as indexuMQBOXsJGOMeta } from "/app/pages/api/rest/v1/partner/apps/retrieve/index.vue?macro=true";
import { default as indexppTbuKOplLMeta } from "/app/pages/api/rest/v1/partner/apps/update/index.vue?macro=true";
import { default as indexVPvHlEojyXMeta } from "/app/pages/api/rest/v1/partner/autocomplete/roles/index.vue?macro=true";
import { default as index1XyqfSFtjsMeta } from "/app/pages/api/rest/v1/partner/cash-collection/payers/list/index.vue?macro=true";
import { default as indexXH8xh5SdEIMeta } from "/app/pages/api/rest/v1/partner/cash-collection/payers/retrieve/index.vue?macro=true";
import { default as indexA5Ua9iBd1lMeta } from "/app/pages/api/rest/v1/partner/cash-collection/transactions/list/index.vue?macro=true";
import { default as index06c3y91Hx3Meta } from "/app/pages/api/rest/v1/partner/cash-collection/transactions/pay/index.vue?macro=true";
import { default as indexqFlh6jgRQkMeta } from "/app/pages/api/rest/v1/partner/cash-collection/transactions/retrieve/index.vue?macro=true";
import { default as indexaPuahRjHWEMeta } from "/app/pages/api/rest/v1/partner/cash-collection/transactions/update/index.vue?macro=true";
import { default as indexBKWQBwlVckMeta } from "/app/pages/api/rest/v1/partner/dashboard/transactions/index.vue?macro=true";
import { default as indexJh8kKtVIW1Meta } from "/app/pages/api/rest/v1/partner/employee-invitation/accept/index.vue?macro=true";
import { default as indexwl8VPN1IzlMeta } from "/app/pages/api/rest/v1/partner/employee-invitation/retrieve-info/index.vue?macro=true";
import { default as indexNYCtrMsd6AMeta } from "/app/pages/api/rest/v1/partner/employee/authentication/login/index.vue?macro=true";
import { default as indexRGpzu6E4LaMeta } from "/app/pages/api/rest/v1/partner/employee/authentication/logout/index.vue?macro=true";
import { default as indexCBS10luIQ6Meta } from "/app/pages/api/rest/v1/partner/employee/authentication/process-social-login/index.vue?macro=true";
import { default as index9dgsdL8tkrMeta } from "/app/pages/api/rest/v1/partner/employee/authentication/request-social-login/index.vue?macro=true";
import { default as indexAtwYPhAHHcMeta } from "/app/pages/api/rest/v1/partner/employee/notifications/delete/index.vue?macro=true";
import { default as indexBED7IH9ZBoMeta } from "/app/pages/api/rest/v1/partner/employee/notifications/list/index.vue?macro=true";
import { default as indexluCi9OZyuRMeta } from "/app/pages/api/rest/v1/partner/employee/notifications/mark-all-as-read/index.vue?macro=true";
import { default as indexQUcgMc0Il8Meta } from "/app/pages/api/rest/v1/partner/employee/notifications/mark/index.vue?macro=true";
import { default as indexVxHC0v8AHDMeta } from "/app/pages/api/rest/v1/partner/employee/notifications/retrieve/index.vue?macro=true";
import { default as indexCKI4vzwvCtMeta } from "/app/pages/api/rest/v1/partner/employee/notifications/unread-count/index.vue?macro=true";
import { default as indexKbgc1hHVOaMeta } from "/app/pages/api/rest/v1/partner/employee/password-reset/process-reset/index.vue?macro=true";
import { default as index6vB2zs6T0OMeta } from "/app/pages/api/rest/v1/partner/employee/password-reset/request-info/index.vue?macro=true";
import { default as indexW1dCBJROIxMeta } from "/app/pages/api/rest/v1/partner/employee/password-reset/request-reset/index.vue?macro=true";
import { default as index9YuoNYu0ahMeta } from "/app/pages/api/rest/v1/partner/employee/profile/avatar/delete/index.vue?macro=true";
import { default as index5BOP8RNoQGMeta } from "/app/pages/api/rest/v1/partner/employee/profile/avatar/upload/index.vue?macro=true";
import { default as indextieBj70ddPMeta } from "/app/pages/api/rest/v1/partner/employee/profile/password-change/index.vue?macro=true";
import { default as indexT7wiPgu9QCMeta } from "/app/pages/api/rest/v1/partner/employee/profile/phonenumber/send-otp/index.vue?macro=true";
import { default as indexoSAypJfzMYMeta } from "/app/pages/api/rest/v1/partner/employee/profile/phonenumber/verify-otp/index.vue?macro=true";
import { default as indexRJWpNDrmolMeta } from "/app/pages/api/rest/v1/partner/employee/profile/update/index.vue?macro=true";
import { default as indexCp8m5K2ovlMeta } from "/app/pages/api/rest/v1/partner/employee/profile/verify-email/index.vue?macro=true";
import { default as indexiObXTE91YgMeta } from "/app/pages/api/rest/v1/partner/employee/whoami/index.vue?macro=true";
import { default as index2Fn2ZUkhuEMeta } from "/app/pages/api/rest/v1/partner/employees/cancel-invitation/index.vue?macro=true";
import { default as indexPetcDvx9JhMeta } from "/app/pages/api/rest/v1/partner/employees/delete/index.vue?macro=true";
import { default as indexN91wJjORXIMeta } from "/app/pages/api/rest/v1/partner/employees/employee-activation/index.vue?macro=true";
import { default as index8WF2rx5bWdMeta } from "/app/pages/api/rest/v1/partner/employees/invite/index.vue?macro=true";
import { default as indexVaetv94dxiMeta } from "/app/pages/api/rest/v1/partner/employees/list/index.vue?macro=true";
import { default as indexGn4h5xbNvBMeta } from "/app/pages/api/rest/v1/partner/employees/resend-invitation/index.vue?macro=true";
import { default as indexo2iK5kppdnMeta } from "/app/pages/api/rest/v1/partner/employees/retrieve/index.vue?macro=true";
import { default as index2VvHK9cTDnMeta } from "/app/pages/api/rest/v1/partner/employees/update/index.vue?macro=true";
import { default as indexuiGMun6wIWMeta } from "/app/pages/api/rest/v1/partner/partner/whoami/index.vue?macro=true";
import { default as indexJhhUSY4uYhMeta } from "/app/pages/api/rest/v1/partner/permissions/list/index.vue?macro=true";
import { default as indexbStbTJhMWZMeta } from "/app/pages/api/rest/v1/partner/roles/create/index.vue?macro=true";
import { default as index9s78atCi9cMeta } from "/app/pages/api/rest/v1/partner/roles/list/index.vue?macro=true";
import { default as indexKTSU8KDhbUMeta } from "/app/pages/api/rest/v1/partner/roles/retrieve/index.vue?macro=true";
import { default as index2Bw2IZAmhiMeta } from "/app/pages/api/rest/v1/partner/roles/update/index.vue?macro=true";
import { default as index4SkkaLNkGwMeta } from "/app/pages/api/rest/v1/partner/support/comments/create/index.vue?macro=true";
import { default as indexga8089WhQoMeta } from "/app/pages/api/rest/v1/partner/support/comments/list/index.vue?macro=true";
import { default as indexstj4tlqZPKMeta } from "/app/pages/api/rest/v1/partner/support/comments/retrieve/index.vue?macro=true";
import { default as indexEySMwwr6SJMeta } from "/app/pages/api/rest/v1/partner/support/tickets/close/index.vue?macro=true";
import { default as indexTgXLczwgZFMeta } from "/app/pages/api/rest/v1/partner/support/tickets/create/index.vue?macro=true";
import { default as indexihJgJCknFpMeta } from "/app/pages/api/rest/v1/partner/support/tickets/list/index.vue?macro=true";
import { default as index98CHhwDDqzMeta } from "/app/pages/api/rest/v1/partner/support/tickets/retrieve/index.vue?macro=true";
import { default as indexdJzo7pDxnwMeta } from "/app/pages/api/rest/v1/partner/verify-user-email/index.vue?macro=true";
import { default as indexZcz8zQz4AQMeta } from "/app/pages/api/rest/v1/payiano/autocomplete/roles/index.vue?macro=true";
import { default as indexiPsOudTDb2Meta } from "/app/pages/api/rest/v1/payiano/employee-invitation/accept/index.vue?macro=true";
import { default as indexryUiuIneTwMeta } from "/app/pages/api/rest/v1/payiano/employee-invitation/info/index.vue?macro=true";
import { default as indexJGlCbmjoYYMeta } from "/app/pages/api/rest/v1/payiano/employee/authentication/login/index.vue?macro=true";
import { default as indexTfK3yAfRPBMeta } from "/app/pages/api/rest/v1/payiano/employee/authentication/logout/index.vue?macro=true";
import { default as indexqPoCVoXjXpMeta } from "/app/pages/api/rest/v1/payiano/employee/authentication/process-social-login/index.vue?macro=true";
import { default as indexfUu8d8il9XMeta } from "/app/pages/api/rest/v1/payiano/employee/authentication/request-social-login/index.vue?macro=true";
import { default as index8DFSxFRHWnMeta } from "/app/pages/api/rest/v1/payiano/employee/notifications/delete/index.vue?macro=true";
import { default as indexf1OVtNYjJ0Meta } from "/app/pages/api/rest/v1/payiano/employee/notifications/list/index.vue?macro=true";
import { default as indexwImzmuOLuGMeta } from "/app/pages/api/rest/v1/payiano/employee/notifications/mark-alls-as-read/index.vue?macro=true";
import { default as indexad2zFyoin6Meta } from "/app/pages/api/rest/v1/payiano/employee/notifications/marks/index.vue?macro=true";
import { default as index1n41nAzh6yMeta } from "/app/pages/api/rest/v1/payiano/employee/notifications/retrieve/index.vue?macro=true";
import { default as indexdKUnKhNSRYMeta } from "/app/pages/api/rest/v1/payiano/employee/notifications/unread-count/index.vue?macro=true";
import { default as index3L1PP7NiVVMeta } from "/app/pages/api/rest/v1/payiano/employee/password-reset/process-reset/index.vue?macro=true";
import { default as indexQUMOmfNF4xMeta } from "/app/pages/api/rest/v1/payiano/employee/password-reset/request-info/index.vue?macro=true";
import { default as indexlYjsr9uBgFMeta } from "/app/pages/api/rest/v1/payiano/employee/password-reset/request-reset/index.vue?macro=true";
import { default as indexoD9A04Gr6QMeta } from "/app/pages/api/rest/v1/payiano/employee/profile/avatar/delete/index.vue?macro=true";
import { default as indexVT9hiIw63PMeta } from "/app/pages/api/rest/v1/payiano/employee/profile/avatar/upload/index.vue?macro=true";
import { default as indexSTqXGnKMrHMeta } from "/app/pages/api/rest/v1/payiano/employee/profile/password-change/index.vue?macro=true";
import { default as index9RoU0DSzZiMeta } from "/app/pages/api/rest/v1/payiano/employee/whoami/index.vue?macro=true";
import { default as indexTo2PN1WhRnMeta } from "/app/pages/api/rest/v1/payiano/employees/cancel-invitation/index.vue?macro=true";
import { default as indexHa0cavKsgcMeta } from "/app/pages/api/rest/v1/payiano/employees/delete/index.vue?macro=true";
import { default as indexQOelZkkV1QMeta } from "/app/pages/api/rest/v1/payiano/employees/employee-activation/index.vue?macro=true";
import { default as indexBcgkndcJIYMeta } from "/app/pages/api/rest/v1/payiano/employees/invite/index.vue?macro=true";
import { default as index1HSVaswY7hMeta } from "/app/pages/api/rest/v1/payiano/employees/list/index.vue?macro=true";
import { default as index7oLVdG84cfMeta } from "/app/pages/api/rest/v1/payiano/employees/resend-invitation/index.vue?macro=true";
import { default as indexaHueh10IexMeta } from "/app/pages/api/rest/v1/payiano/employees/retrieve/index.vue?macro=true";
import { default as index3t3Ksl9E8sMeta } from "/app/pages/api/rest/v1/payiano/employees/update/index.vue?macro=true";
import { default as indexKEhy5SHx5XMeta } from "/app/pages/api/rest/v1/payiano/partners/activation/index.vue?macro=true";
import { default as indexESo2glKkbBMeta } from "/app/pages/api/rest/v1/payiano/partners/cash-collection/transactions/list/index.vue?macro=true";
import { default as indexMVyHIAUnTTMeta } from "/app/pages/api/rest/v1/payiano/partners/cash-collection/transactions/retrieve/index.vue?macro=true";
import { default as indexAT5ATQMa1UMeta } from "/app/pages/api/rest/v1/payiano/partners/employees/cancel-invitation/index.vue?macro=true";
import { default as indexlo8ypjAP6LMeta } from "/app/pages/api/rest/v1/payiano/partners/employees/invite/index.vue?macro=true";
import { default as indexcD44csWKV0Meta } from "/app/pages/api/rest/v1/payiano/partners/employees/list/index.vue?macro=true";
import { default as indexXjWQXpEZ06Meta } from "/app/pages/api/rest/v1/payiano/partners/employees/resend-invitation/index.vue?macro=true";
import { default as indexLnTIz7Qpx0Meta } from "/app/pages/api/rest/v1/payiano/partners/employees/retrieve/index.vue?macro=true";
import { default as indexHaszERcy1KMeta } from "/app/pages/api/rest/v1/payiano/partners/list/index.vue?macro=true";
import { default as indexvI5FOH0iLtMeta } from "/app/pages/api/rest/v1/payiano/partners/retrieve/index.vue?macro=true";
import { default as indexVHWnq7BX0DMeta } from "/app/pages/api/rest/v1/payiano/permissions/list/index.vue?macro=true";
import { default as index5OGPW1LQyHMeta } from "/app/pages/api/rest/v1/payiano/roles/create/index.vue?macro=true";
import { default as indexmRukfoMXlEMeta } from "/app/pages/api/rest/v1/payiano/roles/list/index.vue?macro=true";
import { default as indexceZXXsaVQJMeta } from "/app/pages/api/rest/v1/payiano/roles/retrieve/index.vue?macro=true";
import { default as indexRlyW6Wz8spMeta } from "/app/pages/api/rest/v1/payiano/roles/update/index.vue?macro=true";
import { default as indexaXaWBr3Fe8Meta } from "/app/pages/api/rest/v1/payment/checkouts/pay/index.vue?macro=true";
import { default as indexwN2AoGxzk4Meta } from "/app/pages/api/rest/v1/payment/checkouts/retrieve/index.vue?macro=true";
import { default as indexnZ4McZVHx1Meta } from "/app/pages/api/rest/v1/payment/checkouts/sessions/create/index.vue?macro=true";
import { default as indexpKsw7FNbeTMeta } from "/app/pages/api/rest/v1/payment/checkouts/sessions/retrieve/index.vue?macro=true";
import { default as indexVzhJvMngjCMeta } from "/app/pages/api/rest/v1/payment/offline-order-pay/details/index.vue?macro=true";
import { default as indexMHLsXdXr9TMeta } from "/app/pages/api/rest/v1/payment/offline-order-pay/process/index.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as indexifjs3HQctcMeta } from "/app/pages/plugins/pay-button/index.vue?macro=true";
export default [
  {
    name: "api-rest-get-started",
    path: "/api/rest/get-started",
    component: () => import("/app/pages/api/rest/get-started/index.vue")
  },
  {
    name: "api-rest-reference-currency-codes",
    path: "/api/rest/reference/currency-codes",
    component: () => import("/app/pages/api/rest/reference/currency-codes/index.vue")
  },
  {
    name: "api-rest-reference-datetime-formats",
    path: "/api/rest/reference/datetime-formats",
    component: () => import("/app/pages/api/rest/reference/datetime-formats/index.vue")
  },
  {
    name: "api-rest-reference-errors-handling",
    path: "/api/rest/reference/errors-handling",
    component: () => import("/app/pages/api/rest/reference/errors-handling/index.vue")
  },
  {
    name: "api-rest-reference-identify-resource",
    path: "/api/rest/reference/identify-resource",
    component: () => import("/app/pages/api/rest/reference/identify-resource/index.vue")
  },
  {
    name: "api-rest-reference-including-resources",
    path: "/api/rest/reference/including-resources",
    component: () => import("/app/pages/api/rest/reference/including-resources/index.vue")
  },
  {
    name: "api-rest-reference-pagination",
    path: "/api/rest/reference/pagination",
    component: () => import("/app/pages/api/rest/reference/pagination/index.vue")
  },
  {
    name: "api-rest-reference-processing-fees-strategy",
    path: "/api/rest/reference/processing-fees-strategy",
    component: () => import("/app/pages/api/rest/reference/processing-fees-strategy/index.vue")
  },
  {
    name: "api-rest-reference-tax-calculations",
    path: "/api/rest/reference/tax-calculations",
    component: () => import("/app/pages/api/rest/reference/tax-calculations/index.vue")
  },
  {
    name: "api-rest-reference-test-pay-order",
    path: "/api/rest/reference/test-pay-order",
    component: () => import("/app/pages/api/rest/reference/test-pay-order/index.vue")
  },
  {
    name: "api-rest-reference-webhooks-build-signature",
    path: "/api/rest/reference/webhooks/build-signature",
    component: () => import("/app/pages/api/rest/reference/webhooks/build-signature/index.vue")
  },
  {
    name: "api-rest-reference-webhooks-event-types",
    path: "/api/rest/reference/webhooks/event-types",
    component: () => import("/app/pages/api/rest/reference/webhooks/event-types/index.vue")
  },
  {
    name: "api-rest-reference-webhooks-overview",
    path: "/api/rest/reference/webhooks/overview",
    component: () => import("/app/pages/api/rest/reference/webhooks/overview/index.vue")
  },
  {
    name: "api-rest-reference-webhooks-verify-signature",
    path: "/api/rest/reference/webhooks/verify-signature",
    component: () => import("/app/pages/api/rest/reference/webhooks/verify-signature/index.vue")
  },
  {
    name: "api-rest-v1-ally-basata-bills-inquiry",
    path: "/api/rest/v1/ally/basata/bills/inquiry",
    meta: index74vvd40ExvMeta || {},
    component: () => import("/app/pages/api/rest/v1/ally/basata/bills/inquiry/index.vue")
  },
  {
    name: "api-rest-v1-ally-basata-bills-pay",
    path: "/api/rest/v1/ally/basata/bills/pay",
    meta: index95JOcyUVimMeta || {},
    component: () => import("/app/pages/api/rest/v1/ally/basata/bills/pay/index.vue")
  },
  {
    name: "api-rest-v1-ally-basata-bills-status",
    path: "/api/rest/v1/ally/basata/bills/status",
    meta: indexsTtx7nbuJtMeta || {},
    component: () => import("/app/pages/api/rest/v1/ally/basata/bills/status/index.vue")
  },
  {
    name: "api-rest-v1-company-app-whoami",
    path: "/api/rest/v1/company/app/whoami",
    component: () => import("/app/pages/api/rest/v1/company/app/whoami/index.vue")
  },
  {
    name: "api-rest-v1-company-apps-create",
    path: "/api/rest/v1/company/apps/create",
    meta: indexYyNTrzXtBzMeta || {},
    component: () => import("/app/pages/api/rest/v1/company/apps/create/index.vue")
  },
  {
    name: "api-rest-v1-company-apps-list",
    path: "/api/rest/v1/company/apps/list",
    meta: indexyO8ZV3twjLMeta || {},
    component: () => import("/app/pages/api/rest/v1/company/apps/list/index.vue")
  },
  {
    name: "api-rest-v1-company-apps-reset-token",
    path: "/api/rest/v1/company/apps/reset-token",
    meta: index6XQDBN4X64Meta || {},
    component: () => import("/app/pages/api/rest/v1/company/apps/reset-token/index.vue")
  },
  {
    name: "api-rest-v1-company-apps-retrieve",
    path: "/api/rest/v1/company/apps/retrieve",
    meta: indexCp2B9QSVOEMeta || {},
    component: () => import("/app/pages/api/rest/v1/company/apps/retrieve/index.vue")
  },
  {
    name: "api-rest-v1-company-apps-update",
    path: "/api/rest/v1/company/apps/update",
    meta: indexoelom24NlwMeta || {},
    component: () => import("/app/pages/api/rest/v1/company/apps/update/index.vue")
  },
  {
    name: "api-rest-v1-company-autocomplete-apps",
    path: "/api/rest/v1/company/autocomplete/apps",
    meta: indexeiXkcMkMFjMeta || {},
    component: () => import("/app/pages/api/rest/v1/company/autocomplete/apps/index.vue")
  },
  {
    name: "api-rest-v1-company-autocomplete-customer-tags",
    path: "/api/rest/v1/company/autocomplete/customer-tags",
    meta: indexNVkEYtvfttMeta || {},
    component: () => import("/app/pages/api/rest/v1/company/autocomplete/customer-tags/index.vue")
  },
  {
    name: "api-rest-v1-company-autocomplete-customers",
    path: "/api/rest/v1/company/autocomplete/customers",
    meta: indexW1U5nPs9K1Meta || {},
    component: () => import("/app/pages/api/rest/v1/company/autocomplete/customers/index.vue")
  },
  {
    name: "api-rest-v1-company-autocomplete-payment-methods",
    path: "/api/rest/v1/company/autocomplete/payment-methods",
    meta: index6dxGLiyAySMeta || {},
    component: () => import("/app/pages/api/rest/v1/company/autocomplete/payment-methods/index.vue")
  },
  {
    name: "api-rest-v1-company-autocomplete-roles",
    path: "/api/rest/v1/company/autocomplete/roles",
    meta: indexkul1rExoanMeta || {},
    component: () => import("/app/pages/api/rest/v1/company/autocomplete/roles/index.vue")
  },
  {
    name: "api-rest-v1-company-autocomplete-taxes",
    path: "/api/rest/v1/company/autocomplete/taxes",
    meta: indexavdxwkpmTMMeta || {},
    component: () => import("/app/pages/api/rest/v1/company/autocomplete/taxes/index.vue")
  },
  {
    name: "api-rest-v1-company-autocomplete-webhook-event-types",
    path: "/api/rest/v1/company/autocomplete/webhook-event-types",
    meta: indexj3D06vviO5Meta || {},
    component: () => import("/app/pages/api/rest/v1/company/autocomplete/webhook-event-types/index.vue")
  },
  {
    name: "api-rest-v1-company-autocomplete-webhook-urls",
    path: "/api/rest/v1/company/autocomplete/webhook-urls",
    meta: indexwdYjcMvZMIMeta || {},
    component: () => import("/app/pages/api/rest/v1/company/autocomplete/webhook-urls/index.vue")
  },
  {
    name: "api-rest-v1-company-company-approval-request",
    path: "/api/rest/v1/company/company/approval/request",
    meta: indexSHdL8v4RPVMeta || {},
    component: () => import("/app/pages/api/rest/v1/company/company/approval/request/index.vue")
  },
  {
    name: "api-rest-v1-company-company-approval-retrieve",
    path: "/api/rest/v1/company/company/approval/retrieve",
    meta: indexpoe26gb8ZnMeta || {},
    component: () => import("/app/pages/api/rest/v1/company/company/approval/retrieve/index.vue")
  },
  {
    name: "api-rest-v1-company-company-avatar-delete",
    path: "/api/rest/v1/company/company/avatar/delete",
    meta: indexySuvHZkIcnMeta || {},
    component: () => import("/app/pages/api/rest/v1/company/company/avatar/delete/index.vue")
  },
  {
    name: "api-rest-v1-company-company-avatar-upload",
    path: "/api/rest/v1/company/company/avatar/upload",
    meta: indexYgZJ0EF3TjMeta || {},
    component: () => import("/app/pages/api/rest/v1/company/company/avatar/upload/index.vue")
  },
  {
    name: "api-rest-v1-company-company-business-retrieve",
    path: "/api/rest/v1/company/company/business/retrieve",
    meta: indexNmbxMceK8bMeta || {},
    component: () => import("/app/pages/api/rest/v1/company/company/business/retrieve/index.vue")
  },
  {
    name: "api-rest-v1-company-company-business-update",
    path: "/api/rest/v1/company/company/business/update",
    meta: indexZdZHfIN2e3Meta || {},
    component: () => import("/app/pages/api/rest/v1/company/company/business/update/index.vue")
  },
  {
    name: "api-rest-v1-company-company-company",
    path: "/api/rest/v1/company/company/company",
    meta: indexNkvDpy7YaRMeta || {},
    component: () => import("/app/pages/api/rest/v1/company/company/company/index.vue")
  },
  {
    name: "api-rest-v1-company-company-documents-list",
    path: "/api/rest/v1/company/company/documents/list",
    meta: indexCL9kYo6MwMMeta || {},
    component: () => import("/app/pages/api/rest/v1/company/company/documents/list/index.vue")
  },
  {
    name: "api-rest-v1-company-company-documents-upload",
    path: "/api/rest/v1/company/company/documents/upload",
    meta: indexW0r9R94z9hMeta || {},
    component: () => import("/app/pages/api/rest/v1/company/company/documents/upload/index.vue")
  },
  {
    name: "api-rest-v1-company-company-info-retrieve",
    path: "/api/rest/v1/company/company/info/retrieve",
    meta: indexnfFcvpvkSeMeta || {},
    component: () => import("/app/pages/api/rest/v1/company/company/info/retrieve/index.vue")
  },
  {
    name: "api-rest-v1-company-company-info-update",
    path: "/api/rest/v1/company/company/info/update",
    meta: indexuQHJls4RyEMeta || {},
    component: () => import("/app/pages/api/rest/v1/company/company/info/update/index.vue")
  },
  {
    name: "api-rest-v1-company-company-owners-create",
    path: "/api/rest/v1/company/company/owners/create",
    meta: indexD80DWF9T20Meta || {},
    component: () => import("/app/pages/api/rest/v1/company/company/owners/create/index.vue")
  },
  {
    name: "api-rest-v1-company-company-owners-delete",
    path: "/api/rest/v1/company/company/owners/delete",
    meta: indexXjrrUB9LYDMeta || {},
    component: () => import("/app/pages/api/rest/v1/company/company/owners/delete/index.vue")
  },
  {
    name: "api-rest-v1-company-company-owners-documents-list",
    path: "/api/rest/v1/company/company/owners/documents/list",
    meta: indexecUtvY8yrEMeta || {},
    component: () => import("/app/pages/api/rest/v1/company/company/owners/documents/list/index.vue")
  },
  {
    name: "api-rest-v1-company-company-owners-documents-upload",
    path: "/api/rest/v1/company/company/owners/documents/upload",
    meta: indexFWHdaue7qkMeta || {},
    component: () => import("/app/pages/api/rest/v1/company/company/owners/documents/upload/index.vue")
  },
  {
    name: "api-rest-v1-company-company-owners-list",
    path: "/api/rest/v1/company/company/owners/list",
    meta: indexD6wkO2AZV3Meta || {},
    component: () => import("/app/pages/api/rest/v1/company/company/owners/list/index.vue")
  },
  {
    name: "api-rest-v1-company-company-owners-restore",
    path: "/api/rest/v1/company/company/owners/restore",
    meta: indexLBP26pjIvqMeta || {},
    component: () => import("/app/pages/api/rest/v1/company/company/owners/restore/index.vue")
  },
  {
    name: "api-rest-v1-company-company-owners-retrieve",
    path: "/api/rest/v1/company/company/owners/retrieve",
    meta: indexWtGWkoZ714Meta || {},
    component: () => import("/app/pages/api/rest/v1/company/company/owners/retrieve/index.vue")
  },
  {
    name: "api-rest-v1-company-company-owners-update",
    path: "/api/rest/v1/company/company/owners/update",
    meta: index23lNpLSwBEMeta || {},
    component: () => import("/app/pages/api/rest/v1/company/company/owners/update/index.vue")
  },
  {
    name: "api-rest-v1-company-company-username-check",
    path: "/api/rest/v1/company/company/username/check",
    meta: indexezBB05sjveMeta || {},
    component: () => import("/app/pages/api/rest/v1/company/company/username/check/index.vue")
  },
  {
    name: "api-rest-v1-company-company-username-retrieve",
    path: "/api/rest/v1/company/company/username/retrieve",
    meta: index5wBlKmW92wMeta || {},
    component: () => import("/app/pages/api/rest/v1/company/company/username/retrieve/index.vue")
  },
  {
    name: "api-rest-v1-company-company-username-update",
    path: "/api/rest/v1/company/company/username/update",
    meta: indexYFB5WLUx0aMeta || {},
    component: () => import("/app/pages/api/rest/v1/company/company/username/update/index.vue")
  },
  {
    name: "api-rest-v1-company-customers-addresses-delete",
    path: "/api/rest/v1/company/customers/addresses/delete",
    component: () => import("/app/pages/api/rest/v1/company/customers/addresses/delete/index.vue")
  },
  {
    name: "api-rest-v1-company-customers-addresses-list",
    path: "/api/rest/v1/company/customers/addresses/list",
    component: () => import("/app/pages/api/rest/v1/company/customers/addresses/list/index.vue")
  },
  {
    name: "api-rest-v1-company-customers-addresses-restore",
    path: "/api/rest/v1/company/customers/addresses/restore",
    component: () => import("/app/pages/api/rest/v1/company/customers/addresses/restore/index.vue")
  },
  {
    name: "api-rest-v1-company-customers-addresses-retrieve",
    path: "/api/rest/v1/company/customers/addresses/retrieve",
    component: () => import("/app/pages/api/rest/v1/company/customers/addresses/retrieve/index.vue")
  },
  {
    name: "api-rest-v1-company-customers-addresses-update",
    path: "/api/rest/v1/company/customers/addresses/update",
    component: () => import("/app/pages/api/rest/v1/company/customers/addresses/update/index.vue")
  },
  {
    name: "api-rest-v1-company-customers-create",
    path: "/api/rest/v1/company/customers/create",
    component: () => import("/app/pages/api/rest/v1/company/customers/create/index.vue")
  },
  {
    name: "api-rest-v1-company-customers-customer-addresses-create",
    path: "/api/rest/v1/company/customers/customer-addresses/create",
    component: () => import("/app/pages/api/rest/v1/company/customers/customer-addresses/create/index.vue")
  },
  {
    name: "api-rest-v1-company-customers-customer-addresses-list",
    path: "/api/rest/v1/company/customers/customer-addresses/list",
    component: () => import("/app/pages/api/rest/v1/company/customers/customer-addresses/list/index.vue")
  },
  {
    name: "api-rest-v1-company-customers-list",
    path: "/api/rest/v1/company/customers/list",
    component: () => import("/app/pages/api/rest/v1/company/customers/list/index.vue")
  },
  {
    name: "api-rest-v1-company-customers-retrieve",
    path: "/api/rest/v1/company/customers/retrieve",
    component: () => import("/app/pages/api/rest/v1/company/customers/retrieve/index.vue")
  },
  {
    name: "api-rest-v1-company-customers-tags-create",
    path: "/api/rest/v1/company/customers/tags/create",
    component: () => import("/app/pages/api/rest/v1/company/customers/tags/create/index.vue")
  },
  {
    name: "api-rest-v1-company-customers-tags-delete",
    path: "/api/rest/v1/company/customers/tags/delete",
    component: () => import("/app/pages/api/rest/v1/company/customers/tags/delete/index.vue")
  },
  {
    name: "api-rest-v1-company-customers-tags-list",
    path: "/api/rest/v1/company/customers/tags/list",
    component: () => import("/app/pages/api/rest/v1/company/customers/tags/list/index.vue")
  },
  {
    name: "api-rest-v1-company-customers-tags-restore",
    path: "/api/rest/v1/company/customers/tags/restore",
    component: () => import("/app/pages/api/rest/v1/company/customers/tags/restore/index.vue")
  },
  {
    name: "api-rest-v1-company-customers-tags-retrieve",
    path: "/api/rest/v1/company/customers/tags/retrieve",
    component: () => import("/app/pages/api/rest/v1/company/customers/tags/retrieve/index.vue")
  },
  {
    name: "api-rest-v1-company-customers-tags-update",
    path: "/api/rest/v1/company/customers/tags/update",
    component: () => import("/app/pages/api/rest/v1/company/customers/tags/update/index.vue")
  },
  {
    name: "api-rest-v1-company-customers-update",
    path: "/api/rest/v1/company/customers/update",
    component: () => import("/app/pages/api/rest/v1/company/customers/update/index.vue")
  },
  {
    name: "api-rest-v1-company-discounts-create",
    path: "/api/rest/v1/company/discounts/create",
    component: () => import("/app/pages/api/rest/v1/company/discounts/create/index.vue")
  },
  {
    name: "api-rest-v1-company-discounts-list",
    path: "/api/rest/v1/company/discounts/list",
    component: () => import("/app/pages/api/rest/v1/company/discounts/list/index.vue")
  },
  {
    name: "api-rest-v1-company-discounts-retrieve",
    path: "/api/rest/v1/company/discounts/retrieve",
    component: () => import("/app/pages/api/rest/v1/company/discounts/retrieve/index.vue")
  },
  {
    name: "api-rest-v1-company-discounts-update",
    path: "/api/rest/v1/company/discounts/update",
    component: () => import("/app/pages/api/rest/v1/company/discounts/update/index.vue")
  },
  {
    name: "api-rest-v1-company-employee-logout",
    path: "/api/rest/v1/company/employee/logout",
    meta: indexbtTq9Jh35kMeta || {},
    component: () => import("/app/pages/api/rest/v1/company/employee/logout/index.vue")
  },
  {
    name: "api-rest-v1-company-employee-notifications-delete",
    path: "/api/rest/v1/company/employee/notifications/delete",
    meta: indexhzRfcMOAA1Meta || {},
    component: () => import("/app/pages/api/rest/v1/company/employee/notifications/delete/index.vue")
  },
  {
    name: "api-rest-v1-company-employee-notifications-list",
    path: "/api/rest/v1/company/employee/notifications/list",
    meta: indexqXmlYnyGsZMeta || {},
    component: () => import("/app/pages/api/rest/v1/company/employee/notifications/list/index.vue")
  },
  {
    name: "api-rest-v1-company-employee-notifications-mark-all-as-read",
    path: "/api/rest/v1/company/employee/notifications/mark-all-as-read",
    meta: index7K1i8SzhTXMeta || {},
    component: () => import("/app/pages/api/rest/v1/company/employee/notifications/mark-all-as-read/index.vue")
  },
  {
    name: "api-rest-v1-company-employee-notifications-mark",
    path: "/api/rest/v1/company/employee/notifications/mark",
    meta: indexRbPFMU9vABMeta || {},
    component: () => import("/app/pages/api/rest/v1/company/employee/notifications/mark/index.vue")
  },
  {
    name: "api-rest-v1-company-employee-notifications-retrieve",
    path: "/api/rest/v1/company/employee/notifications/retrieve",
    meta: index2PttIDFincMeta || {},
    component: () => import("/app/pages/api/rest/v1/company/employee/notifications/retrieve/index.vue")
  },
  {
    name: "api-rest-v1-company-employee-notifications-unread-count",
    path: "/api/rest/v1/company/employee/notifications/unread-count",
    meta: indexEWyAbzxSTqMeta || {},
    component: () => import("/app/pages/api/rest/v1/company/employee/notifications/unread-count/index.vue")
  },
  {
    name: "api-rest-v1-company-employee-whoami",
    path: "/api/rest/v1/company/employee/whoami",
    meta: index3m6EX3vBDkMeta || {},
    component: () => import("/app/pages/api/rest/v1/company/employee/whoami/index.vue")
  },
  {
    name: "api-rest-v1-company-employees-activation",
    path: "/api/rest/v1/company/employees/activation",
    meta: indexXX14BoGLTtMeta || {},
    component: () => import("/app/pages/api/rest/v1/company/employees/activation/index.vue")
  },
  {
    name: "api-rest-v1-company-employees-cancel-invitation",
    path: "/api/rest/v1/company/employees/cancel-invitation",
    meta: indexBHIKjyBZRLMeta || {},
    component: () => import("/app/pages/api/rest/v1/company/employees/cancel-invitation/index.vue")
  },
  {
    name: "api-rest-v1-company-employees-delete",
    path: "/api/rest/v1/company/employees/delete",
    meta: indexfjZUMlHBTtMeta || {},
    component: () => import("/app/pages/api/rest/v1/company/employees/delete/index.vue")
  },
  {
    name: "api-rest-v1-company-employees-invite",
    path: "/api/rest/v1/company/employees/invite",
    meta: indextkZzbWsyddMeta || {},
    component: () => import("/app/pages/api/rest/v1/company/employees/invite/index.vue")
  },
  {
    name: "api-rest-v1-company-employees-list",
    path: "/api/rest/v1/company/employees/list",
    meta: indexiXOsobJIYxMeta || {},
    component: () => import("/app/pages/api/rest/v1/company/employees/list/index.vue")
  },
  {
    name: "api-rest-v1-company-employees-resend-invitation",
    path: "/api/rest/v1/company/employees/resend-invitation",
    meta: indexBGRGs2yqBGMeta || {},
    component: () => import("/app/pages/api/rest/v1/company/employees/resend-invitation/index.vue")
  },
  {
    name: "api-rest-v1-company-employees-retrieve",
    path: "/api/rest/v1/company/employees/retrieve",
    meta: indexkoUxSpJOJnMeta || {},
    component: () => import("/app/pages/api/rest/v1/company/employees/retrieve/index.vue")
  },
  {
    name: "api-rest-v1-company-employees-update",
    path: "/api/rest/v1/company/employees/update",
    meta: indexVoSyvRKuYPMeta || {},
    component: () => import("/app/pages/api/rest/v1/company/employees/update/index.vue")
  },
  {
    name: "api-rest-v1-company-online-payments-balances-list",
    path: "/api/rest/v1/company/online-payments/balances/list",
    component: () => import("/app/pages/api/rest/v1/company/online-payments/balances/list/index.vue")
  },
  {
    name: "api-rest-v1-company-online-payments-balances-retrieve",
    path: "/api/rest/v1/company/online-payments/balances/retrieve",
    component: () => import("/app/pages/api/rest/v1/company/online-payments/balances/retrieve/index.vue")
  },
  {
    name: "api-rest-v1-company-online-payments-checkouts-list",
    path: "/api/rest/v1/company/online-payments/checkouts/list",
    component: () => import("/app/pages/api/rest/v1/company/online-payments/checkouts/list/index.vue")
  },
  {
    name: "api-rest-v1-company-online-payments-checkouts-retrieve",
    path: "/api/rest/v1/company/online-payments/checkouts/retrieve",
    component: () => import("/app/pages/api/rest/v1/company/online-payments/checkouts/retrieve/index.vue")
  },
  {
    name: "api-rest-v1-company-online-payments-payment-links-cancel",
    path: "/api/rest/v1/company/online-payments/payment-links/cancel",
    component: () => import("/app/pages/api/rest/v1/company/online-payments/payment-links/cancel/index.vue")
  },
  {
    name: "api-rest-v1-company-online-payments-payment-links-create",
    path: "/api/rest/v1/company/online-payments/payment-links/create",
    component: () => import("/app/pages/api/rest/v1/company/online-payments/payment-links/create/index.vue")
  },
  {
    name: "api-rest-v1-company-online-payments-payment-links-list",
    path: "/api/rest/v1/company/online-payments/payment-links/list",
    component: () => import("/app/pages/api/rest/v1/company/online-payments/payment-links/list/index.vue")
  },
  {
    name: "api-rest-v1-company-online-payments-payment-links-retrieve-checkout",
    path: "/api/rest/v1/company/online-payments/payment-links/retrieve-checkout",
    component: () => import("/app/pages/api/rest/v1/company/online-payments/payment-links/retrieve-checkout/index.vue")
  },
  {
    name: "api-rest-v1-company-online-payments-payment-links-retrieve",
    path: "/api/rest/v1/company/online-payments/payment-links/retrieve",
    component: () => import("/app/pages/api/rest/v1/company/online-payments/payment-links/retrieve/index.vue")
  },
  {
    name: "api-rest-v1-company-online-payments-payment-links-update",
    path: "/api/rest/v1/company/online-payments/payment-links/update",
    component: () => import("/app/pages/api/rest/v1/company/online-payments/payment-links/update/index.vue")
  },
  {
    name: "api-rest-v1-company-online-payments-payment-methods-list",
    path: "/api/rest/v1/company/online-payments/payment-methods/list",
    component: () => import("/app/pages/api/rest/v1/company/online-payments/payment-methods/list/index.vue")
  },
  {
    name: "api-rest-v1-company-online-payments-payment-methods-lists-currencies",
    path: "/api/rest/v1/company/online-payments/payment-methods/lists-currencies",
    component: () => import("/app/pages/api/rest/v1/company/online-payments/payment-methods/lists-currencies/index.vue")
  },
  {
    name: "api-rest-v1-company-online-payments-payment-methods-retrieve",
    path: "/api/rest/v1/company/online-payments/payment-methods/retrieve",
    component: () => import("/app/pages/api/rest/v1/company/online-payments/payment-methods/retrieve/index.vue")
  },
  {
    name: "api-rest-v1-company-online-payments-payment-methods-update",
    path: "/api/rest/v1/company/online-payments/payment-methods/update",
    component: () => import("/app/pages/api/rest/v1/company/online-payments/payment-methods/update/index.vue")
  },
  {
    name: "api-rest-v1-company-online-payments-transactions-list",
    path: "/api/rest/v1/company/online-payments/transactions/list",
    component: () => import("/app/pages/api/rest/v1/company/online-payments/transactions/list/index.vue")
  },
  {
    name: "api-rest-v1-company-online-payments-transactions-retrieve",
    path: "/api/rest/v1/company/online-payments/transactions/retrieve",
    component: () => import("/app/pages/api/rest/v1/company/online-payments/transactions/retrieve/index.vue")
  },
  {
    name: "api-rest-v1-company-permissions-list",
    path: "/api/rest/v1/company/permissions/list",
    meta: indexw2MmvB00BxMeta || {},
    component: () => import("/app/pages/api/rest/v1/company/permissions/list/index.vue")
  },
  {
    name: "api-rest-v1-company-request-logs-list",
    path: "/api/rest/v1/company/request-logs/list",
    component: () => import("/app/pages/api/rest/v1/company/request-logs/list/index.vue")
  },
  {
    name: "api-rest-v1-company-request-logs-retrieve",
    path: "/api/rest/v1/company/request-logs/retrieve",
    component: () => import("/app/pages/api/rest/v1/company/request-logs/retrieve/index.vue")
  },
  {
    name: "api-rest-v1-company-roles-create",
    path: "/api/rest/v1/company/roles/create",
    meta: indexGQvAH9ZBj3Meta || {},
    component: () => import("/app/pages/api/rest/v1/company/roles/create/index.vue")
  },
  {
    name: "api-rest-v1-company-roles-list",
    path: "/api/rest/v1/company/roles/list",
    meta: indexNZWH4NRHsgMeta || {},
    component: () => import("/app/pages/api/rest/v1/company/roles/list/index.vue")
  },
  {
    name: "api-rest-v1-company-roles-retrieve",
    path: "/api/rest/v1/company/roles/retrieve",
    meta: indexjAREkE75MIMeta || {},
    component: () => import("/app/pages/api/rest/v1/company/roles/retrieve/index.vue")
  },
  {
    name: "api-rest-v1-company-roles-update",
    path: "/api/rest/v1/company/roles/update",
    meta: indexGQe8yNxPrvMeta || {},
    component: () => import("/app/pages/api/rest/v1/company/roles/update/index.vue")
  },
  {
    name: "api-rest-v1-company-store-brands-create",
    path: "/api/rest/v1/company/store/brands/create",
    component: () => import("/app/pages/api/rest/v1/company/store/brands/create/index.vue")
  },
  {
    name: "api-rest-v1-company-store-brands-delete",
    path: "/api/rest/v1/company/store/brands/delete",
    component: () => import("/app/pages/api/rest/v1/company/store/brands/delete/index.vue")
  },
  {
    name: "api-rest-v1-company-store-brands-list",
    path: "/api/rest/v1/company/store/brands/list",
    component: () => import("/app/pages/api/rest/v1/company/store/brands/list/index.vue")
  },
  {
    name: "api-rest-v1-company-store-brands-restore",
    path: "/api/rest/v1/company/store/brands/restore",
    component: () => import("/app/pages/api/rest/v1/company/store/brands/restore/index.vue")
  },
  {
    name: "api-rest-v1-company-store-brands-retrieve",
    path: "/api/rest/v1/company/store/brands/retrieve",
    component: () => import("/app/pages/api/rest/v1/company/store/brands/retrieve/index.vue")
  },
  {
    name: "api-rest-v1-company-store-brands-update",
    path: "/api/rest/v1/company/store/brands/update",
    component: () => import("/app/pages/api/rest/v1/company/store/brands/update/index.vue")
  },
  {
    name: "api-rest-v1-company-store-categories-create",
    path: "/api/rest/v1/company/store/categories/create",
    component: () => import("/app/pages/api/rest/v1/company/store/categories/create/index.vue")
  },
  {
    name: "api-rest-v1-company-store-categories-delete",
    path: "/api/rest/v1/company/store/categories/delete",
    component: () => import("/app/pages/api/rest/v1/company/store/categories/delete/index.vue")
  },
  {
    name: "api-rest-v1-company-store-categories-list",
    path: "/api/rest/v1/company/store/categories/list",
    component: () => import("/app/pages/api/rest/v1/company/store/categories/list/index.vue")
  },
  {
    name: "api-rest-v1-company-store-categories-restore",
    path: "/api/rest/v1/company/store/categories/restore",
    component: () => import("/app/pages/api/rest/v1/company/store/categories/restore/index.vue")
  },
  {
    name: "api-rest-v1-company-store-categories-retrieve",
    path: "/api/rest/v1/company/store/categories/retrieve",
    component: () => import("/app/pages/api/rest/v1/company/store/categories/retrieve/index.vue")
  },
  {
    name: "api-rest-v1-company-store-categories-update",
    path: "/api/rest/v1/company/store/categories/update",
    component: () => import("/app/pages/api/rest/v1/company/store/categories/update/index.vue")
  },
  {
    name: "api-rest-v1-company-support-comments-create",
    path: "/api/rest/v1/company/support/comments/create",
    meta: indexmuijLcXQo7Meta || {},
    component: () => import("/app/pages/api/rest/v1/company/support/comments/create/index.vue")
  },
  {
    name: "api-rest-v1-company-support-comments-list",
    path: "/api/rest/v1/company/support/comments/list",
    meta: indexFwF85zsK7GMeta || {},
    component: () => import("/app/pages/api/rest/v1/company/support/comments/list/index.vue")
  },
  {
    name: "api-rest-v1-company-support-comments-retrieve",
    path: "/api/rest/v1/company/support/comments/retrieve",
    meta: indexZZjuHSNDcMMeta || {},
    component: () => import("/app/pages/api/rest/v1/company/support/comments/retrieve/index.vue")
  },
  {
    name: "api-rest-v1-company-support-tickets-close",
    path: "/api/rest/v1/company/support/tickets/close",
    meta: indexKxXXBjrrT3Meta || {},
    component: () => import("/app/pages/api/rest/v1/company/support/tickets/close/index.vue")
  },
  {
    name: "api-rest-v1-company-support-tickets-create",
    path: "/api/rest/v1/company/support/tickets/create",
    meta: indexNNkXxSM8KFMeta || {},
    component: () => import("/app/pages/api/rest/v1/company/support/tickets/create/index.vue")
  },
  {
    name: "api-rest-v1-company-support-tickets-list",
    path: "/api/rest/v1/company/support/tickets/list",
    meta: indexu4edU2CzwMMeta || {},
    component: () => import("/app/pages/api/rest/v1/company/support/tickets/list/index.vue")
  },
  {
    name: "api-rest-v1-company-support-tickets-retrieve",
    path: "/api/rest/v1/company/support/tickets/retrieve",
    meta: index8V2yMAPQjEMeta || {},
    component: () => import("/app/pages/api/rest/v1/company/support/tickets/retrieve/index.vue")
  },
  {
    name: "api-rest-v1-company-taxes-create",
    path: "/api/rest/v1/company/taxes/create",
    component: () => import("/app/pages/api/rest/v1/company/taxes/create/index.vue")
  },
  {
    name: "api-rest-v1-company-taxes-list",
    path: "/api/rest/v1/company/taxes/list",
    component: () => import("/app/pages/api/rest/v1/company/taxes/list/index.vue")
  },
  {
    name: "api-rest-v1-company-taxes-retrieve",
    path: "/api/rest/v1/company/taxes/retrieve",
    component: () => import("/app/pages/api/rest/v1/company/taxes/retrieve/index.vue")
  },
  {
    name: "api-rest-v1-company-taxes-update",
    path: "/api/rest/v1/company/taxes/update",
    component: () => import("/app/pages/api/rest/v1/company/taxes/update/index.vue")
  },
  {
    name: "api-rest-v1-company-webhooks-signatures-verify",
    path: "/api/rest/v1/company/webhooks/signatures/verify",
    component: () => import("/app/pages/api/rest/v1/company/webhooks/signatures/verify/index.vue")
  },
  {
    name: "api-rest-v1-company-webhooks-webhook-event-attempts-list",
    path: "/api/rest/v1/company/webhooks/webhook-event-attempts/list",
    component: () => import("/app/pages/api/rest/v1/company/webhooks/webhook-event-attempts/list/index.vue")
  },
  {
    name: "api-rest-v1-company-webhooks-webhook-event-attempts-retrieve",
    path: "/api/rest/v1/company/webhooks/webhook-event-attempts/retrieve",
    component: () => import("/app/pages/api/rest/v1/company/webhooks/webhook-event-attempts/retrieve/index.vue")
  },
  {
    name: "api-rest-v1-company-webhooks-webhook-event-types-list",
    path: "/api/rest/v1/company/webhooks/webhook-event-types/list",
    meta: indexuB41IGBHeDMeta || {},
    component: () => import("/app/pages/api/rest/v1/company/webhooks/webhook-event-types/list/index.vue")
  },
  {
    name: "api-rest-v1-company-webhooks-webhook-events-attempts-list",
    path: "/api/rest/v1/company/webhooks/webhook-events/attempts/list",
    component: () => import("/app/pages/api/rest/v1/company/webhooks/webhook-events/attempts/list/index.vue")
  },
  {
    name: "api-rest-v1-company-webhooks-webhook-events-attempts-send",
    path: "/api/rest/v1/company/webhooks/webhook-events/attempts/send",
    component: () => import("/app/pages/api/rest/v1/company/webhooks/webhook-events/attempts/send/index.vue")
  },
  {
    name: "api-rest-v1-company-webhooks-webhook-events-delete",
    path: "/api/rest/v1/company/webhooks/webhook-events/delete",
    component: () => import("/app/pages/api/rest/v1/company/webhooks/webhook-events/delete/index.vue")
  },
  {
    name: "api-rest-v1-company-webhooks-webhook-events-list",
    path: "/api/rest/v1/company/webhooks/webhook-events/list",
    component: () => import("/app/pages/api/rest/v1/company/webhooks/webhook-events/list/index.vue")
  },
  {
    name: "api-rest-v1-company-webhooks-webhook-events-restore",
    path: "/api/rest/v1/company/webhooks/webhook-events/restore",
    component: () => import("/app/pages/api/rest/v1/company/webhooks/webhook-events/restore/index.vue")
  },
  {
    name: "api-rest-v1-company-webhooks-webhook-events-retrieve",
    path: "/api/rest/v1/company/webhooks/webhook-events/retrieve",
    component: () => import("/app/pages/api/rest/v1/company/webhooks/webhook-events/retrieve/index.vue")
  },
  {
    name: "api-rest-v1-company-webhooks-webhook-urls-create",
    path: "/api/rest/v1/company/webhooks/webhook-urls/create",
    meta: indexORrSwTSONyMeta || {},
    component: () => import("/app/pages/api/rest/v1/company/webhooks/webhook-urls/create/index.vue")
  },
  {
    name: "api-rest-v1-company-webhooks-webhook-urls-delete",
    path: "/api/rest/v1/company/webhooks/webhook-urls/delete",
    meta: index8XpHUgFvlOMeta || {},
    component: () => import("/app/pages/api/rest/v1/company/webhooks/webhook-urls/delete/index.vue")
  },
  {
    name: "api-rest-v1-company-webhooks-webhook-urls-list",
    path: "/api/rest/v1/company/webhooks/webhook-urls/list",
    meta: indexkv9tVs6JcNMeta || {},
    component: () => import("/app/pages/api/rest/v1/company/webhooks/webhook-urls/list/index.vue")
  },
  {
    name: "api-rest-v1-company-webhooks-webhook-urls-reset-signature",
    path: "/api/rest/v1/company/webhooks/webhook-urls/reset-signature",
    meta: indexZjxvNztEywMeta || {},
    component: () => import("/app/pages/api/rest/v1/company/webhooks/webhook-urls/reset-signature/index.vue")
  },
  {
    name: "api-rest-v1-company-webhooks-webhook-urls-restore",
    path: "/api/rest/v1/company/webhooks/webhook-urls/restore",
    meta: indexz4n2fhUb3fMeta || {},
    component: () => import("/app/pages/api/rest/v1/company/webhooks/webhook-urls/restore/index.vue")
  },
  {
    name: "api-rest-v1-company-webhooks-webhook-urls-retrieve",
    path: "/api/rest/v1/company/webhooks/webhook-urls/retrieve",
    meta: indexd0zvHFUyOhMeta || {},
    component: () => import("/app/pages/api/rest/v1/company/webhooks/webhook-urls/retrieve/index.vue")
  },
  {
    name: "api-rest-v1-company-webhooks-webhook-urls-update",
    path: "/api/rest/v1/company/webhooks/webhook-urls/update",
    meta: indexhwrpLXoOXUMeta || {},
    component: () => import("/app/pages/api/rest/v1/company/webhooks/webhook-urls/update/index.vue")
  },
  {
    name: "api-rest-v1-individual-authentication-login",
    path: "/api/rest/v1/individual/authentication/login",
    meta: indexor38VDvidCMeta || {},
    component: () => import("/app/pages/api/rest/v1/individual/authentication/login/index.vue")
  },
  {
    name: "api-rest-v1-individual-authentication-logout",
    path: "/api/rest/v1/individual/authentication/logout",
    meta: index7pvMSLNPmOMeta || {},
    component: () => import("/app/pages/api/rest/v1/individual/authentication/logout/index.vue")
  },
  {
    name: "api-rest-v1-individual-authentication-process-social-login",
    path: "/api/rest/v1/individual/authentication/process-social-login",
    meta: indexvzKxb084OxMeta || {},
    component: () => import("/app/pages/api/rest/v1/individual/authentication/process-social-login/index.vue")
  },
  {
    name: "api-rest-v1-individual-authentication-register",
    path: "/api/rest/v1/individual/authentication/register",
    meta: indexptzQizHBt7Meta || {},
    component: () => import("/app/pages/api/rest/v1/individual/authentication/register/index.vue")
  },
  {
    name: "api-rest-v1-individual-authentication-request-social-login",
    path: "/api/rest/v1/individual/authentication/request-social-login",
    meta: indexW2w830sMmmMeta || {},
    component: () => import("/app/pages/api/rest/v1/individual/authentication/request-social-login/index.vue")
  },
  {
    name: "api-rest-v1-individual-companies-create",
    path: "/api/rest/v1/individual/companies/create",
    meta: indexzQFKpkltKkMeta || {},
    component: () => import("/app/pages/api/rest/v1/individual/companies/create/index.vue")
  },
  {
    name: "api-rest-v1-individual-companies-pay-on-behalf",
    path: "/api/rest/v1/individual/companies/pay-on-behalf",
    meta: indexidyiAWMxFeMeta || {},
    component: () => import("/app/pages/api/rest/v1/individual/companies/pay-on-behalf/index.vue")
  },
  {
    name: "api-rest-v1-individual-employment-invitation-accept",
    path: "/api/rest/v1/individual/employment-invitation/accept",
    meta: indexn6l9holkZgMeta || {},
    component: () => import("/app/pages/api/rest/v1/individual/employment-invitation/accept/index.vue")
  },
  {
    name: "api-rest-v1-individual-employment-invitation-retrieve-info",
    path: "/api/rest/v1/individual/employment-invitation/retrieve-info",
    meta: indexKtrPTb8MPwMeta || {},
    component: () => import("/app/pages/api/rest/v1/individual/employment-invitation/retrieve-info/index.vue")
  },
  {
    name: "api-rest-v1-individual-employments-list",
    path: "/api/rest/v1/individual/employments/list",
    meta: indexn6ol2s6pfgMeta || {},
    component: () => import("/app/pages/api/rest/v1/individual/employments/list/index.vue")
  },
  {
    name: "api-rest-v1-individual-employments-retrieve",
    path: "/api/rest/v1/individual/employments/retrieve",
    meta: index1M8DGtQz8KMeta || {},
    component: () => import("/app/pages/api/rest/v1/individual/employments/retrieve/index.vue")
  },
  {
    name: "api-rest-v1-individual-employments-token",
    path: "/api/rest/v1/individual/employments/token",
    meta: indext94wjvEITDMeta || {},
    component: () => import("/app/pages/api/rest/v1/individual/employments/token/index.vue")
  },
  {
    name: "api-rest-v1-individual-notifications-delete",
    path: "/api/rest/v1/individual/notifications/delete",
    meta: indexoGBGfDi8r9Meta || {},
    component: () => import("/app/pages/api/rest/v1/individual/notifications/delete/index.vue")
  },
  {
    name: "api-rest-v1-individual-notifications-list",
    path: "/api/rest/v1/individual/notifications/list",
    meta: indexFXjcXOxd0AMeta || {},
    component: () => import("/app/pages/api/rest/v1/individual/notifications/list/index.vue")
  },
  {
    name: "api-rest-v1-individual-notifications-mark-all-as-read",
    path: "/api/rest/v1/individual/notifications/mark-all-as-read",
    meta: index6dphKidcqYMeta || {},
    component: () => import("/app/pages/api/rest/v1/individual/notifications/mark-all-as-read/index.vue")
  },
  {
    name: "api-rest-v1-individual-notifications-mark",
    path: "/api/rest/v1/individual/notifications/mark",
    meta: indexjQDg3QGjU3Meta || {},
    component: () => import("/app/pages/api/rest/v1/individual/notifications/mark/index.vue")
  },
  {
    name: "api-rest-v1-individual-notifications-retrieve",
    path: "/api/rest/v1/individual/notifications/retrieve",
    meta: indexsDF9gXjvSfMeta || {},
    component: () => import("/app/pages/api/rest/v1/individual/notifications/retrieve/index.vue")
  },
  {
    name: "api-rest-v1-individual-notifications-unread-count",
    path: "/api/rest/v1/individual/notifications/unread-count",
    meta: indexIGr70wmFTuMeta || {},
    component: () => import("/app/pages/api/rest/v1/individual/notifications/unread-count/index.vue")
  },
  {
    name: "api-rest-v1-individual-password-reset-process-reset",
    path: "/api/rest/v1/individual/password-reset/process-reset",
    meta: indexCUJ2aJ3qYKMeta || {},
    component: () => import("/app/pages/api/rest/v1/individual/password-reset/process-reset/index.vue")
  },
  {
    name: "api-rest-v1-individual-password-reset-request-info",
    path: "/api/rest/v1/individual/password-reset/request-info",
    meta: indexiYPPb88mBjMeta || {},
    component: () => import("/app/pages/api/rest/v1/individual/password-reset/request-info/index.vue")
  },
  {
    name: "api-rest-v1-individual-password-reset-request-reset",
    path: "/api/rest/v1/individual/password-reset/request-reset",
    meta: indexDjwOMLNes2Meta || {},
    component: () => import("/app/pages/api/rest/v1/individual/password-reset/request-reset/index.vue")
  },
  {
    name: "api-rest-v1-individual-profile-approval-request",
    path: "/api/rest/v1/individual/profile/approval/request",
    meta: indexsk3Dg5nlMaMeta || {},
    component: () => import("/app/pages/api/rest/v1/individual/profile/approval/request/index.vue")
  },
  {
    name: "api-rest-v1-individual-profile-approval-retrieve",
    path: "/api/rest/v1/individual/profile/approval/retrieve",
    meta: indexbLX217l9bHMeta || {},
    component: () => import("/app/pages/api/rest/v1/individual/profile/approval/retrieve/index.vue")
  },
  {
    name: "api-rest-v1-individual-profile-avatar-delete",
    path: "/api/rest/v1/individual/profile/avatar/delete",
    meta: indexgGOwHixoVbMeta || {},
    component: () => import("/app/pages/api/rest/v1/individual/profile/avatar/delete/index.vue")
  },
  {
    name: "api-rest-v1-individual-profile-avatar-upload",
    path: "/api/rest/v1/individual/profile/avatar/upload",
    meta: indexyQMSu5gsK2Meta || {},
    component: () => import("/app/pages/api/rest/v1/individual/profile/avatar/upload/index.vue")
  },
  {
    name: "api-rest-v1-individual-profile-documents-list",
    path: "/api/rest/v1/individual/profile/documents/list",
    meta: indexqJk4Xb0AoVMeta || {},
    component: () => import("/app/pages/api/rest/v1/individual/profile/documents/list/index.vue")
  },
  {
    name: "api-rest-v1-individual-profile-documents-upload",
    path: "/api/rest/v1/individual/profile/documents/upload",
    meta: indexj0on0HWaL5Meta || {},
    component: () => import("/app/pages/api/rest/v1/individual/profile/documents/upload/index.vue")
  },
  {
    name: "api-rest-v1-individual-profile-password-change",
    path: "/api/rest/v1/individual/profile/password-change",
    meta: indexL7wkpXaD4ZMeta || {},
    component: () => import("/app/pages/api/rest/v1/individual/profile/password-change/index.vue")
  },
  {
    name: "api-rest-v1-individual-profile-phonenumber-send-otp",
    path: "/api/rest/v1/individual/profile/phonenumber/send-otp",
    meta: index4ITA137TNuMeta || {},
    component: () => import("/app/pages/api/rest/v1/individual/profile/phonenumber/send-otp/index.vue")
  },
  {
    name: "api-rest-v1-individual-profile-phonenumber-verify-otp",
    path: "/api/rest/v1/individual/profile/phonenumber/verify-otp",
    meta: indexp2nSjHpQiXMeta || {},
    component: () => import("/app/pages/api/rest/v1/individual/profile/phonenumber/verify-otp/index.vue")
  },
  {
    name: "api-rest-v1-individual-profile-update",
    path: "/api/rest/v1/individual/profile/update",
    meta: index3gPYtiAUi7Meta || {},
    component: () => import("/app/pages/api/rest/v1/individual/profile/update/index.vue")
  },
  {
    name: "api-rest-v1-individual-profile-verify-email",
    path: "/api/rest/v1/individual/profile/verify-email",
    meta: indexfkA0Sjz8wzMeta || {},
    component: () => import("/app/pages/api/rest/v1/individual/profile/verify-email/index.vue")
  },
  {
    name: "api-rest-v1-individual-support-comments-create",
    path: "/api/rest/v1/individual/support/comments/create",
    meta: indexnW9U6sa9c6Meta || {},
    component: () => import("/app/pages/api/rest/v1/individual/support/comments/create/index.vue")
  },
  {
    name: "api-rest-v1-individual-support-comments-list",
    path: "/api/rest/v1/individual/support/comments/list",
    meta: indexkT57bsCvhaMeta || {},
    component: () => import("/app/pages/api/rest/v1/individual/support/comments/list/index.vue")
  },
  {
    name: "api-rest-v1-individual-support-comments-retrieve",
    path: "/api/rest/v1/individual/support/comments/retrieve",
    meta: indexQ3PYeBstKnMeta || {},
    component: () => import("/app/pages/api/rest/v1/individual/support/comments/retrieve/index.vue")
  },
  {
    name: "api-rest-v1-individual-support-tickets-close",
    path: "/api/rest/v1/individual/support/tickets/close",
    meta: indexNzLLyURybFMeta || {},
    component: () => import("/app/pages/api/rest/v1/individual/support/tickets/close/index.vue")
  },
  {
    name: "api-rest-v1-individual-support-tickets-create",
    path: "/api/rest/v1/individual/support/tickets/create",
    meta: indexWPIE2HRKFkMeta || {},
    component: () => import("/app/pages/api/rest/v1/individual/support/tickets/create/index.vue")
  },
  {
    name: "api-rest-v1-individual-support-tickets-list",
    path: "/api/rest/v1/individual/support/tickets/list",
    meta: indexfo0ngJMJMlMeta || {},
    component: () => import("/app/pages/api/rest/v1/individual/support/tickets/list/index.vue")
  },
  {
    name: "api-rest-v1-individual-support-tickets-retrieve",
    path: "/api/rest/v1/individual/support/tickets/retrieve",
    meta: indexazF8BMsn5DMeta || {},
    component: () => import("/app/pages/api/rest/v1/individual/support/tickets/retrieve/index.vue")
  },
  {
    name: "api-rest-v1-individual-twofa-activate",
    path: "/api/rest/v1/individual/twofa/activate",
    meta: index8nWKzgkdiOMeta || {},
    component: () => import("/app/pages/api/rest/v1/individual/twofa/activate/index.vue")
  },
  {
    name: "api-rest-v1-individual-twofa-generate",
    path: "/api/rest/v1/individual/twofa/generate",
    meta: indexiLNTDoUaPsMeta || {},
    component: () => import("/app/pages/api/rest/v1/individual/twofa/generate/index.vue")
  },
  {
    name: "api-rest-v1-individual-twofa-reset",
    path: "/api/rest/v1/individual/twofa/reset",
    meta: indexkVEqiOz9LXMeta || {},
    component: () => import("/app/pages/api/rest/v1/individual/twofa/reset/index.vue")
  },
  {
    name: "api-rest-v1-individual-twofa-retrieve",
    path: "/api/rest/v1/individual/twofa/retrieve",
    meta: index5Od8zMztToMeta || {},
    component: () => import("/app/pages/api/rest/v1/individual/twofa/retrieve/index.vue")
  },
  {
    name: "api-rest-v1-individual-username-check",
    path: "/api/rest/v1/individual/username/check",
    meta: indexZL5kbxK0yXMeta || {},
    component: () => import("/app/pages/api/rest/v1/individual/username/check/index.vue")
  },
  {
    name: "api-rest-v1-individual-username-retrieve",
    path: "/api/rest/v1/individual/username/retrieve",
    meta: indexl5e0QcM0q9Meta || {},
    component: () => import("/app/pages/api/rest/v1/individual/username/retrieve/index.vue")
  },
  {
    name: "api-rest-v1-individual-username-update",
    path: "/api/rest/v1/individual/username/update",
    meta: indexixmNmn4bqaMeta || {},
    component: () => import("/app/pages/api/rest/v1/individual/username/update/index.vue")
  },
  {
    name: "api-rest-v1-individual-verify-user-email",
    path: "/api/rest/v1/individual/verify-user-email",
    meta: indexD2DzDA9eNBMeta || {},
    component: () => import("/app/pages/api/rest/v1/individual/verify-user-email/index.vue")
  },
  {
    name: "api-rest-v1-individual-whoami",
    path: "/api/rest/v1/individual/whoami",
    meta: indexQcGnejFgEvMeta || {},
    component: () => import("/app/pages/api/rest/v1/individual/whoami/index.vue")
  },
  {
    name: "api-rest-v1-partner-app-whoami",
    path: "/api/rest/v1/partner/app/whoami",
    component: () => import("/app/pages/api/rest/v1/partner/app/whoami/index.vue")
  },
  {
    name: "api-rest-v1-partner-apps-create",
    path: "/api/rest/v1/partner/apps/create",
    meta: indexuvImvn5TQUMeta || {},
    component: () => import("/app/pages/api/rest/v1/partner/apps/create/index.vue")
  },
  {
    name: "api-rest-v1-partner-apps-list",
    path: "/api/rest/v1/partner/apps/list",
    meta: indexJJ8Uom0tJaMeta || {},
    component: () => import("/app/pages/api/rest/v1/partner/apps/list/index.vue")
  },
  {
    name: "api-rest-v1-partner-apps-reset-token",
    path: "/api/rest/v1/partner/apps/reset-token",
    meta: index2zmMoYnK1xMeta || {},
    component: () => import("/app/pages/api/rest/v1/partner/apps/reset-token/index.vue")
  },
  {
    name: "api-rest-v1-partner-apps-retrieve",
    path: "/api/rest/v1/partner/apps/retrieve",
    meta: indexuMQBOXsJGOMeta || {},
    component: () => import("/app/pages/api/rest/v1/partner/apps/retrieve/index.vue")
  },
  {
    name: "api-rest-v1-partner-apps-update",
    path: "/api/rest/v1/partner/apps/update",
    meta: indexppTbuKOplLMeta || {},
    component: () => import("/app/pages/api/rest/v1/partner/apps/update/index.vue")
  },
  {
    name: "api-rest-v1-partner-autocomplete-roles",
    path: "/api/rest/v1/partner/autocomplete/roles",
    meta: indexVPvHlEojyXMeta || {},
    component: () => import("/app/pages/api/rest/v1/partner/autocomplete/roles/index.vue")
  },
  {
    name: "api-rest-v1-partner-cash-collection-payers-list",
    path: "/api/rest/v1/partner/cash-collection/payers/list",
    component: () => import("/app/pages/api/rest/v1/partner/cash-collection/payers/list/index.vue")
  },
  {
    name: "api-rest-v1-partner-cash-collection-payers-retrieve",
    path: "/api/rest/v1/partner/cash-collection/payers/retrieve",
    component: () => import("/app/pages/api/rest/v1/partner/cash-collection/payers/retrieve/index.vue")
  },
  {
    name: "api-rest-v1-partner-cash-collection-transactions-list",
    path: "/api/rest/v1/partner/cash-collection/transactions/list",
    component: () => import("/app/pages/api/rest/v1/partner/cash-collection/transactions/list/index.vue")
  },
  {
    name: "api-rest-v1-partner-cash-collection-transactions-pay",
    path: "/api/rest/v1/partner/cash-collection/transactions/pay",
    component: () => import("/app/pages/api/rest/v1/partner/cash-collection/transactions/pay/index.vue")
  },
  {
    name: "api-rest-v1-partner-cash-collection-transactions-retrieve",
    path: "/api/rest/v1/partner/cash-collection/transactions/retrieve",
    component: () => import("/app/pages/api/rest/v1/partner/cash-collection/transactions/retrieve/index.vue")
  },
  {
    name: "api-rest-v1-partner-cash-collection-transactions-update",
    path: "/api/rest/v1/partner/cash-collection/transactions/update",
    component: () => import("/app/pages/api/rest/v1/partner/cash-collection/transactions/update/index.vue")
  },
  {
    name: "api-rest-v1-partner-dashboard-transactions",
    path: "/api/rest/v1/partner/dashboard/transactions",
    meta: indexBKWQBwlVckMeta || {},
    component: () => import("/app/pages/api/rest/v1/partner/dashboard/transactions/index.vue")
  },
  {
    name: "api-rest-v1-partner-employee-invitation-accept",
    path: "/api/rest/v1/partner/employee-invitation/accept",
    meta: indexJh8kKtVIW1Meta || {},
    component: () => import("/app/pages/api/rest/v1/partner/employee-invitation/accept/index.vue")
  },
  {
    name: "api-rest-v1-partner-employee-invitation-retrieve-info",
    path: "/api/rest/v1/partner/employee-invitation/retrieve-info",
    meta: indexwl8VPN1IzlMeta || {},
    component: () => import("/app/pages/api/rest/v1/partner/employee-invitation/retrieve-info/index.vue")
  },
  {
    name: "api-rest-v1-partner-employee-authentication-login",
    path: "/api/rest/v1/partner/employee/authentication/login",
    meta: indexNYCtrMsd6AMeta || {},
    component: () => import("/app/pages/api/rest/v1/partner/employee/authentication/login/index.vue")
  },
  {
    name: "api-rest-v1-partner-employee-authentication-logout",
    path: "/api/rest/v1/partner/employee/authentication/logout",
    meta: indexRGpzu6E4LaMeta || {},
    component: () => import("/app/pages/api/rest/v1/partner/employee/authentication/logout/index.vue")
  },
  {
    name: "api-rest-v1-partner-employee-authentication-process-social-login",
    path: "/api/rest/v1/partner/employee/authentication/process-social-login",
    meta: indexCBS10luIQ6Meta || {},
    component: () => import("/app/pages/api/rest/v1/partner/employee/authentication/process-social-login/index.vue")
  },
  {
    name: "api-rest-v1-partner-employee-authentication-request-social-login",
    path: "/api/rest/v1/partner/employee/authentication/request-social-login",
    meta: index9dgsdL8tkrMeta || {},
    component: () => import("/app/pages/api/rest/v1/partner/employee/authentication/request-social-login/index.vue")
  },
  {
    name: "api-rest-v1-partner-employee-notifications-delete",
    path: "/api/rest/v1/partner/employee/notifications/delete",
    meta: indexAtwYPhAHHcMeta || {},
    component: () => import("/app/pages/api/rest/v1/partner/employee/notifications/delete/index.vue")
  },
  {
    name: "api-rest-v1-partner-employee-notifications-list",
    path: "/api/rest/v1/partner/employee/notifications/list",
    meta: indexBED7IH9ZBoMeta || {},
    component: () => import("/app/pages/api/rest/v1/partner/employee/notifications/list/index.vue")
  },
  {
    name: "api-rest-v1-partner-employee-notifications-mark-all-as-read",
    path: "/api/rest/v1/partner/employee/notifications/mark-all-as-read",
    meta: indexluCi9OZyuRMeta || {},
    component: () => import("/app/pages/api/rest/v1/partner/employee/notifications/mark-all-as-read/index.vue")
  },
  {
    name: "api-rest-v1-partner-employee-notifications-mark",
    path: "/api/rest/v1/partner/employee/notifications/mark",
    meta: indexQUcgMc0Il8Meta || {},
    component: () => import("/app/pages/api/rest/v1/partner/employee/notifications/mark/index.vue")
  },
  {
    name: "api-rest-v1-partner-employee-notifications-retrieve",
    path: "/api/rest/v1/partner/employee/notifications/retrieve",
    meta: indexVxHC0v8AHDMeta || {},
    component: () => import("/app/pages/api/rest/v1/partner/employee/notifications/retrieve/index.vue")
  },
  {
    name: "api-rest-v1-partner-employee-notifications-unread-count",
    path: "/api/rest/v1/partner/employee/notifications/unread-count",
    meta: indexCKI4vzwvCtMeta || {},
    component: () => import("/app/pages/api/rest/v1/partner/employee/notifications/unread-count/index.vue")
  },
  {
    name: "api-rest-v1-partner-employee-password-reset-process-reset",
    path: "/api/rest/v1/partner/employee/password-reset/process-reset",
    meta: indexKbgc1hHVOaMeta || {},
    component: () => import("/app/pages/api/rest/v1/partner/employee/password-reset/process-reset/index.vue")
  },
  {
    name: "api-rest-v1-partner-employee-password-reset-request-info",
    path: "/api/rest/v1/partner/employee/password-reset/request-info",
    meta: index6vB2zs6T0OMeta || {},
    component: () => import("/app/pages/api/rest/v1/partner/employee/password-reset/request-info/index.vue")
  },
  {
    name: "api-rest-v1-partner-employee-password-reset-request-reset",
    path: "/api/rest/v1/partner/employee/password-reset/request-reset",
    meta: indexW1dCBJROIxMeta || {},
    component: () => import("/app/pages/api/rest/v1/partner/employee/password-reset/request-reset/index.vue")
  },
  {
    name: "api-rest-v1-partner-employee-profile-avatar-delete",
    path: "/api/rest/v1/partner/employee/profile/avatar/delete",
    meta: index9YuoNYu0ahMeta || {},
    component: () => import("/app/pages/api/rest/v1/partner/employee/profile/avatar/delete/index.vue")
  },
  {
    name: "api-rest-v1-partner-employee-profile-avatar-upload",
    path: "/api/rest/v1/partner/employee/profile/avatar/upload",
    meta: index5BOP8RNoQGMeta || {},
    component: () => import("/app/pages/api/rest/v1/partner/employee/profile/avatar/upload/index.vue")
  },
  {
    name: "api-rest-v1-partner-employee-profile-password-change",
    path: "/api/rest/v1/partner/employee/profile/password-change",
    meta: indextieBj70ddPMeta || {},
    component: () => import("/app/pages/api/rest/v1/partner/employee/profile/password-change/index.vue")
  },
  {
    name: "api-rest-v1-partner-employee-profile-phonenumber-send-otp",
    path: "/api/rest/v1/partner/employee/profile/phonenumber/send-otp",
    meta: indexT7wiPgu9QCMeta || {},
    component: () => import("/app/pages/api/rest/v1/partner/employee/profile/phonenumber/send-otp/index.vue")
  },
  {
    name: "api-rest-v1-partner-employee-profile-phonenumber-verify-otp",
    path: "/api/rest/v1/partner/employee/profile/phonenumber/verify-otp",
    meta: indexoSAypJfzMYMeta || {},
    component: () => import("/app/pages/api/rest/v1/partner/employee/profile/phonenumber/verify-otp/index.vue")
  },
  {
    name: "api-rest-v1-partner-employee-profile-update",
    path: "/api/rest/v1/partner/employee/profile/update",
    meta: indexRJWpNDrmolMeta || {},
    component: () => import("/app/pages/api/rest/v1/partner/employee/profile/update/index.vue")
  },
  {
    name: "api-rest-v1-partner-employee-profile-verify-email",
    path: "/api/rest/v1/partner/employee/profile/verify-email",
    meta: indexCp8m5K2ovlMeta || {},
    component: () => import("/app/pages/api/rest/v1/partner/employee/profile/verify-email/index.vue")
  },
  {
    name: "api-rest-v1-partner-employee-whoami",
    path: "/api/rest/v1/partner/employee/whoami",
    meta: indexiObXTE91YgMeta || {},
    component: () => import("/app/pages/api/rest/v1/partner/employee/whoami/index.vue")
  },
  {
    name: "api-rest-v1-partner-employees-cancel-invitation",
    path: "/api/rest/v1/partner/employees/cancel-invitation",
    meta: index2Fn2ZUkhuEMeta || {},
    component: () => import("/app/pages/api/rest/v1/partner/employees/cancel-invitation/index.vue")
  },
  {
    name: "api-rest-v1-partner-employees-delete",
    path: "/api/rest/v1/partner/employees/delete",
    meta: indexPetcDvx9JhMeta || {},
    component: () => import("/app/pages/api/rest/v1/partner/employees/delete/index.vue")
  },
  {
    name: "api-rest-v1-partner-employees-employee-activation",
    path: "/api/rest/v1/partner/employees/employee-activation",
    meta: indexN91wJjORXIMeta || {},
    component: () => import("/app/pages/api/rest/v1/partner/employees/employee-activation/index.vue")
  },
  {
    name: "api-rest-v1-partner-employees-invite",
    path: "/api/rest/v1/partner/employees/invite",
    meta: index8WF2rx5bWdMeta || {},
    component: () => import("/app/pages/api/rest/v1/partner/employees/invite/index.vue")
  },
  {
    name: "api-rest-v1-partner-employees-list",
    path: "/api/rest/v1/partner/employees/list",
    meta: indexVaetv94dxiMeta || {},
    component: () => import("/app/pages/api/rest/v1/partner/employees/list/index.vue")
  },
  {
    name: "api-rest-v1-partner-employees-resend-invitation",
    path: "/api/rest/v1/partner/employees/resend-invitation",
    meta: indexGn4h5xbNvBMeta || {},
    component: () => import("/app/pages/api/rest/v1/partner/employees/resend-invitation/index.vue")
  },
  {
    name: "api-rest-v1-partner-employees-retrieve",
    path: "/api/rest/v1/partner/employees/retrieve",
    meta: indexo2iK5kppdnMeta || {},
    component: () => import("/app/pages/api/rest/v1/partner/employees/retrieve/index.vue")
  },
  {
    name: "api-rest-v1-partner-employees-update",
    path: "/api/rest/v1/partner/employees/update",
    meta: index2VvHK9cTDnMeta || {},
    component: () => import("/app/pages/api/rest/v1/partner/employees/update/index.vue")
  },
  {
    name: "api-rest-v1-partner-partner-whoami",
    path: "/api/rest/v1/partner/partner/whoami",
    meta: indexuiGMun6wIWMeta || {},
    component: () => import("/app/pages/api/rest/v1/partner/partner/whoami/index.vue")
  },
  {
    name: "api-rest-v1-partner-permissions-list",
    path: "/api/rest/v1/partner/permissions/list",
    meta: indexJhhUSY4uYhMeta || {},
    component: () => import("/app/pages/api/rest/v1/partner/permissions/list/index.vue")
  },
  {
    name: "api-rest-v1-partner-roles-create",
    path: "/api/rest/v1/partner/roles/create",
    meta: indexbStbTJhMWZMeta || {},
    component: () => import("/app/pages/api/rest/v1/partner/roles/create/index.vue")
  },
  {
    name: "api-rest-v1-partner-roles-list",
    path: "/api/rest/v1/partner/roles/list",
    meta: index9s78atCi9cMeta || {},
    component: () => import("/app/pages/api/rest/v1/partner/roles/list/index.vue")
  },
  {
    name: "api-rest-v1-partner-roles-retrieve",
    path: "/api/rest/v1/partner/roles/retrieve",
    meta: indexKTSU8KDhbUMeta || {},
    component: () => import("/app/pages/api/rest/v1/partner/roles/retrieve/index.vue")
  },
  {
    name: "api-rest-v1-partner-roles-update",
    path: "/api/rest/v1/partner/roles/update",
    meta: index2Bw2IZAmhiMeta || {},
    component: () => import("/app/pages/api/rest/v1/partner/roles/update/index.vue")
  },
  {
    name: "api-rest-v1-partner-support-comments-create",
    path: "/api/rest/v1/partner/support/comments/create",
    meta: index4SkkaLNkGwMeta || {},
    component: () => import("/app/pages/api/rest/v1/partner/support/comments/create/index.vue")
  },
  {
    name: "api-rest-v1-partner-support-comments-list",
    path: "/api/rest/v1/partner/support/comments/list",
    meta: indexga8089WhQoMeta || {},
    component: () => import("/app/pages/api/rest/v1/partner/support/comments/list/index.vue")
  },
  {
    name: "api-rest-v1-partner-support-comments-retrieve",
    path: "/api/rest/v1/partner/support/comments/retrieve",
    meta: indexstj4tlqZPKMeta || {},
    component: () => import("/app/pages/api/rest/v1/partner/support/comments/retrieve/index.vue")
  },
  {
    name: "api-rest-v1-partner-support-tickets-close",
    path: "/api/rest/v1/partner/support/tickets/close",
    meta: indexEySMwwr6SJMeta || {},
    component: () => import("/app/pages/api/rest/v1/partner/support/tickets/close/index.vue")
  },
  {
    name: "api-rest-v1-partner-support-tickets-create",
    path: "/api/rest/v1/partner/support/tickets/create",
    meta: indexTgXLczwgZFMeta || {},
    component: () => import("/app/pages/api/rest/v1/partner/support/tickets/create/index.vue")
  },
  {
    name: "api-rest-v1-partner-support-tickets-list",
    path: "/api/rest/v1/partner/support/tickets/list",
    meta: indexihJgJCknFpMeta || {},
    component: () => import("/app/pages/api/rest/v1/partner/support/tickets/list/index.vue")
  },
  {
    name: "api-rest-v1-partner-support-tickets-retrieve",
    path: "/api/rest/v1/partner/support/tickets/retrieve",
    meta: index98CHhwDDqzMeta || {},
    component: () => import("/app/pages/api/rest/v1/partner/support/tickets/retrieve/index.vue")
  },
  {
    name: "api-rest-v1-partner-verify-user-email",
    path: "/api/rest/v1/partner/verify-user-email",
    meta: indexdJzo7pDxnwMeta || {},
    component: () => import("/app/pages/api/rest/v1/partner/verify-user-email/index.vue")
  },
  {
    name: "api-rest-v1-payiano-autocomplete-roles",
    path: "/api/rest/v1/payiano/autocomplete/roles",
    meta: indexZcz8zQz4AQMeta || {},
    component: () => import("/app/pages/api/rest/v1/payiano/autocomplete/roles/index.vue")
  },
  {
    name: "api-rest-v1-payiano-employee-invitation-accept",
    path: "/api/rest/v1/payiano/employee-invitation/accept",
    meta: indexiPsOudTDb2Meta || {},
    component: () => import("/app/pages/api/rest/v1/payiano/employee-invitation/accept/index.vue")
  },
  {
    name: "api-rest-v1-payiano-employee-invitation-info",
    path: "/api/rest/v1/payiano/employee-invitation/info",
    meta: indexryUiuIneTwMeta || {},
    component: () => import("/app/pages/api/rest/v1/payiano/employee-invitation/info/index.vue")
  },
  {
    name: "api-rest-v1-payiano-employee-authentication-login",
    path: "/api/rest/v1/payiano/employee/authentication/login",
    meta: indexJGlCbmjoYYMeta || {},
    component: () => import("/app/pages/api/rest/v1/payiano/employee/authentication/login/index.vue")
  },
  {
    name: "api-rest-v1-payiano-employee-authentication-logout",
    path: "/api/rest/v1/payiano/employee/authentication/logout",
    meta: indexTfK3yAfRPBMeta || {},
    component: () => import("/app/pages/api/rest/v1/payiano/employee/authentication/logout/index.vue")
  },
  {
    name: "api-rest-v1-payiano-employee-authentication-process-social-login",
    path: "/api/rest/v1/payiano/employee/authentication/process-social-login",
    meta: indexqPoCVoXjXpMeta || {},
    component: () => import("/app/pages/api/rest/v1/payiano/employee/authentication/process-social-login/index.vue")
  },
  {
    name: "api-rest-v1-payiano-employee-authentication-request-social-login",
    path: "/api/rest/v1/payiano/employee/authentication/request-social-login",
    meta: indexfUu8d8il9XMeta || {},
    component: () => import("/app/pages/api/rest/v1/payiano/employee/authentication/request-social-login/index.vue")
  },
  {
    name: "api-rest-v1-payiano-employee-notifications-delete",
    path: "/api/rest/v1/payiano/employee/notifications/delete",
    meta: index8DFSxFRHWnMeta || {},
    component: () => import("/app/pages/api/rest/v1/payiano/employee/notifications/delete/index.vue")
  },
  {
    name: "api-rest-v1-payiano-employee-notifications-list",
    path: "/api/rest/v1/payiano/employee/notifications/list",
    meta: indexf1OVtNYjJ0Meta || {},
    component: () => import("/app/pages/api/rest/v1/payiano/employee/notifications/list/index.vue")
  },
  {
    name: "api-rest-v1-payiano-employee-notifications-mark-alls-as-read",
    path: "/api/rest/v1/payiano/employee/notifications/mark-alls-as-read",
    meta: indexwImzmuOLuGMeta || {},
    component: () => import("/app/pages/api/rest/v1/payiano/employee/notifications/mark-alls-as-read/index.vue")
  },
  {
    name: "api-rest-v1-payiano-employee-notifications-marks",
    path: "/api/rest/v1/payiano/employee/notifications/marks",
    meta: indexad2zFyoin6Meta || {},
    component: () => import("/app/pages/api/rest/v1/payiano/employee/notifications/marks/index.vue")
  },
  {
    name: "api-rest-v1-payiano-employee-notifications-retrieve",
    path: "/api/rest/v1/payiano/employee/notifications/retrieve",
    meta: index1n41nAzh6yMeta || {},
    component: () => import("/app/pages/api/rest/v1/payiano/employee/notifications/retrieve/index.vue")
  },
  {
    name: "api-rest-v1-payiano-employee-notifications-unread-count",
    path: "/api/rest/v1/payiano/employee/notifications/unread-count",
    meta: indexdKUnKhNSRYMeta || {},
    component: () => import("/app/pages/api/rest/v1/payiano/employee/notifications/unread-count/index.vue")
  },
  {
    name: "api-rest-v1-payiano-employee-password-reset-process-reset",
    path: "/api/rest/v1/payiano/employee/password-reset/process-reset",
    meta: index3L1PP7NiVVMeta || {},
    component: () => import("/app/pages/api/rest/v1/payiano/employee/password-reset/process-reset/index.vue")
  },
  {
    name: "api-rest-v1-payiano-employee-password-reset-request-info",
    path: "/api/rest/v1/payiano/employee/password-reset/request-info",
    meta: indexQUMOmfNF4xMeta || {},
    component: () => import("/app/pages/api/rest/v1/payiano/employee/password-reset/request-info/index.vue")
  },
  {
    name: "api-rest-v1-payiano-employee-password-reset-request-reset",
    path: "/api/rest/v1/payiano/employee/password-reset/request-reset",
    meta: indexlYjsr9uBgFMeta || {},
    component: () => import("/app/pages/api/rest/v1/payiano/employee/password-reset/request-reset/index.vue")
  },
  {
    name: "api-rest-v1-payiano-employee-profile-avatar-delete",
    path: "/api/rest/v1/payiano/employee/profile/avatar/delete",
    meta: indexoD9A04Gr6QMeta || {},
    component: () => import("/app/pages/api/rest/v1/payiano/employee/profile/avatar/delete/index.vue")
  },
  {
    name: "api-rest-v1-payiano-employee-profile-avatar-upload",
    path: "/api/rest/v1/payiano/employee/profile/avatar/upload",
    meta: indexVT9hiIw63PMeta || {},
    component: () => import("/app/pages/api/rest/v1/payiano/employee/profile/avatar/upload/index.vue")
  },
  {
    name: "api-rest-v1-payiano-employee-profile-password-change",
    path: "/api/rest/v1/payiano/employee/profile/password-change",
    meta: indexSTqXGnKMrHMeta || {},
    component: () => import("/app/pages/api/rest/v1/payiano/employee/profile/password-change/index.vue")
  },
  {
    name: "api-rest-v1-payiano-employee-whoami",
    path: "/api/rest/v1/payiano/employee/whoami",
    meta: index9RoU0DSzZiMeta || {},
    component: () => import("/app/pages/api/rest/v1/payiano/employee/whoami/index.vue")
  },
  {
    name: "api-rest-v1-payiano-employees-cancel-invitation",
    path: "/api/rest/v1/payiano/employees/cancel-invitation",
    meta: indexTo2PN1WhRnMeta || {},
    component: () => import("/app/pages/api/rest/v1/payiano/employees/cancel-invitation/index.vue")
  },
  {
    name: "api-rest-v1-payiano-employees-delete",
    path: "/api/rest/v1/payiano/employees/delete",
    meta: indexHa0cavKsgcMeta || {},
    component: () => import("/app/pages/api/rest/v1/payiano/employees/delete/index.vue")
  },
  {
    name: "api-rest-v1-payiano-employees-employee-activation",
    path: "/api/rest/v1/payiano/employees/employee-activation",
    meta: indexQOelZkkV1QMeta || {},
    component: () => import("/app/pages/api/rest/v1/payiano/employees/employee-activation/index.vue")
  },
  {
    name: "api-rest-v1-payiano-employees-invite",
    path: "/api/rest/v1/payiano/employees/invite",
    meta: indexBcgkndcJIYMeta || {},
    component: () => import("/app/pages/api/rest/v1/payiano/employees/invite/index.vue")
  },
  {
    name: "api-rest-v1-payiano-employees-list",
    path: "/api/rest/v1/payiano/employees/list",
    meta: index1HSVaswY7hMeta || {},
    component: () => import("/app/pages/api/rest/v1/payiano/employees/list/index.vue")
  },
  {
    name: "api-rest-v1-payiano-employees-resend-invitation",
    path: "/api/rest/v1/payiano/employees/resend-invitation",
    meta: index7oLVdG84cfMeta || {},
    component: () => import("/app/pages/api/rest/v1/payiano/employees/resend-invitation/index.vue")
  },
  {
    name: "api-rest-v1-payiano-employees-retrieve",
    path: "/api/rest/v1/payiano/employees/retrieve",
    meta: indexaHueh10IexMeta || {},
    component: () => import("/app/pages/api/rest/v1/payiano/employees/retrieve/index.vue")
  },
  {
    name: "api-rest-v1-payiano-employees-update",
    path: "/api/rest/v1/payiano/employees/update",
    meta: index3t3Ksl9E8sMeta || {},
    component: () => import("/app/pages/api/rest/v1/payiano/employees/update/index.vue")
  },
  {
    name: "api-rest-v1-payiano-partners-activation",
    path: "/api/rest/v1/payiano/partners/activation",
    meta: indexKEhy5SHx5XMeta || {},
    component: () => import("/app/pages/api/rest/v1/payiano/partners/activation/index.vue")
  },
  {
    name: "api-rest-v1-payiano-partners-cash-collection-transactions-list",
    path: "/api/rest/v1/payiano/partners/cash-collection/transactions/list",
    meta: indexESo2glKkbBMeta || {},
    component: () => import("/app/pages/api/rest/v1/payiano/partners/cash-collection/transactions/list/index.vue")
  },
  {
    name: "api-rest-v1-payiano-partners-cash-collection-transactions-retrieve",
    path: "/api/rest/v1/payiano/partners/cash-collection/transactions/retrieve",
    meta: indexMVyHIAUnTTMeta || {},
    component: () => import("/app/pages/api/rest/v1/payiano/partners/cash-collection/transactions/retrieve/index.vue")
  },
  {
    name: "api-rest-v1-payiano-partners-employees-cancel-invitation",
    path: "/api/rest/v1/payiano/partners/employees/cancel-invitation",
    meta: indexAT5ATQMa1UMeta || {},
    component: () => import("/app/pages/api/rest/v1/payiano/partners/employees/cancel-invitation/index.vue")
  },
  {
    name: "api-rest-v1-payiano-partners-employees-invite",
    path: "/api/rest/v1/payiano/partners/employees/invite",
    meta: indexlo8ypjAP6LMeta || {},
    component: () => import("/app/pages/api/rest/v1/payiano/partners/employees/invite/index.vue")
  },
  {
    name: "api-rest-v1-payiano-partners-employees-list",
    path: "/api/rest/v1/payiano/partners/employees/list",
    meta: indexcD44csWKV0Meta || {},
    component: () => import("/app/pages/api/rest/v1/payiano/partners/employees/list/index.vue")
  },
  {
    name: "api-rest-v1-payiano-partners-employees-resend-invitation",
    path: "/api/rest/v1/payiano/partners/employees/resend-invitation",
    meta: indexXjWQXpEZ06Meta || {},
    component: () => import("/app/pages/api/rest/v1/payiano/partners/employees/resend-invitation/index.vue")
  },
  {
    name: "api-rest-v1-payiano-partners-employees-retrieve",
    path: "/api/rest/v1/payiano/partners/employees/retrieve",
    meta: indexLnTIz7Qpx0Meta || {},
    component: () => import("/app/pages/api/rest/v1/payiano/partners/employees/retrieve/index.vue")
  },
  {
    name: "api-rest-v1-payiano-partners-list",
    path: "/api/rest/v1/payiano/partners/list",
    meta: indexHaszERcy1KMeta || {},
    component: () => import("/app/pages/api/rest/v1/payiano/partners/list/index.vue")
  },
  {
    name: "api-rest-v1-payiano-partners-retrieve",
    path: "/api/rest/v1/payiano/partners/retrieve",
    meta: indexvI5FOH0iLtMeta || {},
    component: () => import("/app/pages/api/rest/v1/payiano/partners/retrieve/index.vue")
  },
  {
    name: "api-rest-v1-payiano-permissions-list",
    path: "/api/rest/v1/payiano/permissions/list",
    meta: indexVHWnq7BX0DMeta || {},
    component: () => import("/app/pages/api/rest/v1/payiano/permissions/list/index.vue")
  },
  {
    name: "api-rest-v1-payiano-roles-create",
    path: "/api/rest/v1/payiano/roles/create",
    meta: index5OGPW1LQyHMeta || {},
    component: () => import("/app/pages/api/rest/v1/payiano/roles/create/index.vue")
  },
  {
    name: "api-rest-v1-payiano-roles-list",
    path: "/api/rest/v1/payiano/roles/list",
    meta: indexmRukfoMXlEMeta || {},
    component: () => import("/app/pages/api/rest/v1/payiano/roles/list/index.vue")
  },
  {
    name: "api-rest-v1-payiano-roles-retrieve",
    path: "/api/rest/v1/payiano/roles/retrieve",
    meta: indexceZXXsaVQJMeta || {},
    component: () => import("/app/pages/api/rest/v1/payiano/roles/retrieve/index.vue")
  },
  {
    name: "api-rest-v1-payiano-roles-update",
    path: "/api/rest/v1/payiano/roles/update",
    meta: indexRlyW6Wz8spMeta || {},
    component: () => import("/app/pages/api/rest/v1/payiano/roles/update/index.vue")
  },
  {
    name: "api-rest-v1-payment-checkouts-pay",
    path: "/api/rest/v1/payment/checkouts/pay",
    meta: indexaXaWBr3Fe8Meta || {},
    component: () => import("/app/pages/api/rest/v1/payment/checkouts/pay/index.vue")
  },
  {
    name: "api-rest-v1-payment-checkouts-retrieve",
    path: "/api/rest/v1/payment/checkouts/retrieve",
    meta: indexwN2AoGxzk4Meta || {},
    component: () => import("/app/pages/api/rest/v1/payment/checkouts/retrieve/index.vue")
  },
  {
    name: "api-rest-v1-payment-checkouts-sessions-create",
    path: "/api/rest/v1/payment/checkouts/sessions/create",
    meta: indexnZ4McZVHx1Meta || {},
    component: () => import("/app/pages/api/rest/v1/payment/checkouts/sessions/create/index.vue")
  },
  {
    name: "api-rest-v1-payment-checkouts-sessions-retrieve",
    path: "/api/rest/v1/payment/checkouts/sessions/retrieve",
    meta: indexpKsw7FNbeTMeta || {},
    component: () => import("/app/pages/api/rest/v1/payment/checkouts/sessions/retrieve/index.vue")
  },
  {
    name: "api-rest-v1-payment-offline-order-pay-details",
    path: "/api/rest/v1/payment/offline-order-pay/details",
    meta: indexVzhJvMngjCMeta || {},
    component: () => import("/app/pages/api/rest/v1/payment/offline-order-pay/details/index.vue")
  },
  {
    name: "api-rest-v1-payment-offline-order-pay-process",
    path: "/api/rest/v1/payment/offline-order-pay/process",
    meta: indexMHLsXdXr9TMeta || {},
    component: () => import("/app/pages/api/rest/v1/payment/offline-order-pay/process/index.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "plugins-pay-button",
    path: "/plugins/pay-button",
    component: () => import("/app/pages/plugins/pay-button/index.vue")
  }
]