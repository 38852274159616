import { $singleton, ICountry, getLocaleModule } from '@payiano/ha-external'
import { useRtl } from '@payiano/ha-composables'
import { setI18nLocale } from '@payiano/ha-i18n'
import { Nullable } from '@payiano/ha-types'
import { LocaleInstance } from '@/types'

export default defineNuxtPlugin(async () => {
  const { $i18n, $router } = useNuxtApp()

  const { isRtl } = useRtl()
  const localePath = useLocalePath()

  const country = ref<Nullable<ICountry>>()

  // TODO: enable in production when `api` is deployed so
  // we can get the locale country based on the client IP.
  if (process.env.NODE_ENV === 'development') {
    const $locale = $singleton.get('$locale', getLocaleModule)

    country.value = await $locale.load()
  }

  const setLocale = (code: string, refresh = true) => {
    if (refresh && import.meta.browser) {
      $i18n.setLocaleCookie(code)

      const path = localePath(
        {
          path: $router.currentRoute.value.fullPath,
          query: $router.currentRoute.value.query,
          hash: $router.currentRoute.value.hash
        },
        code
      )

      location.href = path
    } else {
      setI18nLocale(code)
      $i18n.setLocale(code)

      isRtl.value = $i18n.localeProperties.value.dir === 'rtl'
    }
  }

  // TODO: uncomment this when the app is fully localized and locale
  // files are added for all laguages

  // const code =
  //   $i18n.getLocaleCookie() ??
  //   country.value?.lang?.toLocaleLowerCase() ??
  //   $i18n.locale.value

  // setLocale(code, false)

  // Always set locale to `en`
  setLocale('en', false)

  const locale = reactive<LocaleInstance>({
    country,
    setLocale
  })

  return {
    provide: {
      locale
    }
  }
})
