<template>
  <NuxtLoadingIndicator :height="3" color="#1a59dbdd" />

  <NuxtLayout :key="$route.meta.key ? String($route.meta.key) : undefined">
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup lang="ts">
const { t } = useI18n()

const config = useRuntimeConfig()
const { $router } = useNuxtApp()

const title = 'Payiano | Developer'

const description = `
  Explore Payiano’s comprehensive documentation – your guide to navigating
  our secure and reliable payment processing platform with ease. Perfectly
  tuned to assist you in every step, our docs ensure your online payment
  setup performs like a symphony.
`

useSeoMetaTags({
  title,
  description,
  keywords: [
    'Payment Service Provider',
    'Payment Acceptance',
    'Online Payment Gateway',
    'Payment Gateway',
    'Payment Security',
    'Secure Online Payment',
    'Payment Provider API',
    'Make Payments Easily and Securely',
    'Payment Requests',
    'Tracking Payments',
    'E-Commerce',
    'Payments With Ease',
    'Accept Online Payments in Egypt',
    'Payment Link',
    'Pay by link',
    'Instalments',
    'Loyalty Program',
    'E-Wallets',
    'Secure Transactions',
    'Digital Payments',
    'Mobile Payments',
    'Merchant Solutions',
    'Card Payments',
    'Fast and Easy Payments',
    'Payment Processing',
    'Online Shopping',
    'Multi-Currency Support',
    'Contactless Payments',
    'API Integration',
    'Reliable Payment Solutions',
    'Customizable Payment Options',
    'Business Payments',
    'Subscription Payments',
    'Recurring Billing',
    'Smooth Checkout Experience',
    'Payment System',
    'Convenient Payment Experience'
  ]
})

const localeHead = useLocaleHead({
  addDirAttribute: true,
  addSeoAttributes: true
})

const htmlAttrs = computed(() => localeHead.value.htmlAttrs ?? {})

const meta = computed(() => [
  ...(localeHead.value.meta ?? []),
  {
    name: 'robots',
    content: 'index, follow'
  },
  // Facebook Open Graph meta tags
  {
    property: 'og:type',
    content: 'website'
  },
  {
    property: 'og:site_name',
    content: 'payiano'
  },
  {
    property: 'fb:app_id',
    content: config.public.facebook.appId
  },
  {
    property: 'og:image',
    content: `${config.public.siteUrl}/images/social_share_banner.png`
  },
  {
    property: 'og:image:alt',
    content: 'Payiano logo'
  },
  {
    property: 'og:image:width',
    content: 2400
  },
  {
    property: 'og:image:height',
    content: 1260
  },

  // Twitter meta tages
  {
    name: 'twitter:site',
    content: '@payiano'
  },
  {
    name: 'twitter:image',
    content: `${config.public.siteUrl}/images/social_share_banner.png`
  },
  {
    name: 'twitter:image:alt',
    content: 'Payiano logo'
  },
  {
    name: 'twitter:card',
    content: 'summary_large_image'
  },
  {
    name: 'twitter:creator',
    content: '@payiano'
  },

  // Google
  {
    name: 'google-site-verification',
    content: 'wUlk2dY1jLyyzqOdpRBuX0JkUgbZ4fVNixKO1WJ1ShE' // TODO: to be checked if it's verified
  },

  // LinkedIn
  {
    name: 'author',
    property: 'author',
    content: t('payianoTeam')
  },

  // Service worker
  {
    name: 'msapplication-TileColor',
    content: '#000066'
  },
  {
    name: 'msapplication-TileImage',
    content: '/images/favicons/ms-icon-144x144.png'
  },
  {
    name: 'theme-color',
    content: '#000066'
  }
])

const link = computed(() => [
  ...(localeHead.value.link ?? []),
  {
    rel: 'apple-touch-icon',
    sizes: '57x57',
    href: '/images/favicons/apple-icon-57x57.png'
  },
  {
    rel: 'apple-touch-icon',
    sizes: '60x60',
    href: '/images/favicons/apple-icon-60x60.png'
  },
  {
    rel: 'apple-touch-icon',
    sizes: '72x72',
    href: '/images/favicons/apple-icon-72x72.png'
  },
  {
    rel: 'apple-touch-icon',
    sizes: '76x76',
    href: '/images/favicons/apple-icon-76x76.png'
  },
  {
    rel: 'apple-touch-icon',
    sizes: '114x114',
    href: '/images/favicons/apple-icon-114x114.png'
  },
  {
    rel: 'apple-touch-icon',
    sizes: '120x120',
    href: '/images/favicons/apple-icon-120x120.png'
  },
  {
    rel: 'apple-touch-icon',
    sizes: '144x144',
    href: '/images/favicons/apple-icon-144x144.png'
  },
  {
    rel: 'apple-touch-icon',
    sizes: '152x152',
    href: '/images/favicons/apple-icon-152x152.png'
  },
  {
    rel: 'apple-touch-icon',
    sizes: '180x180',
    href: '/images/favicons/apple-icon-180x180.png'
  },
  {
    rel: 'icon',
    type: 'image/png',
    sizes: '192x192',
    href: '/images/favicons/android-icon-192x192.png'
  },
  {
    rel: 'icon',
    type: 'image/png',
    sizes: '32x32',
    href: '/images/favicons/favicon-32x32.png'
  },
  {
    rel: 'icon',
    type: 'image/png',
    sizes: '96x96',
    href: '/images/favicons/favicon-96x96.png'
  },
  {
    rel: 'icon',
    type: 'image/png',
    sizes: '16x16',
    href: '/images/favicons/favicon-16x16.png'
  },

  // Alternate hrefs.
  {
    rel: 'alternate',
    hreflang: 'en',
    href: `${config.public.siteUrl}${$router.currentRoute.value.path}`
  },
  {
    rel: 'alternate',
    hreflang: 'en-GB',
    href: `${config.public.siteUrl}${$router.currentRoute.value.path}`
  },
  {
    rel: 'alternate',
    hreflang: 'x-default',
    href: `${config.public.siteUrl}${$router.currentRoute.value.path}`
  }
])

useHead({
  htmlAttrs,
  meta,
  link
})
</script>
