import { IPaginationResponse } from '@payiano/ha-types'
import { useAuthService } from '@/composables'
import { AccessTokenType, ApiDomain, Auth, Individual } from '@/types'

export default defineNuxtPlugin(() => {
  const domain: ApiDomain = 'INDIVIDUAL'
  const tokenName: AccessTokenType = 'INDIVIDUAL_TOKEN'

  const { $http, $token, $login } = useNuxtApp()
  const apiBaseUrl = useApiBaseUrl(domain)

  const employments = ref<Individual.Employment[]>([])

  const loadEmployments = async (): Promise<void> => {
    const { data } = await $http<IPaginationResponse<Individual.Employment>>(
      tokenName,
      {
        url: '/employments',
        baseURL: apiBaseUrl.value,
        params: {
          amount: 200,
          includes: {
            employment: ['company']
          }
        },
        metadata: {
          silent: true
        }
      }
    )

    employments.value = data.items
  }

  const _individual = useAuthService<Individual.User>({
    domain,
    tokenName,
    whoamiPath: '/whoami',
    includes: {
      //
    },
    onLoadProfile: async () => {
      await loadEmployments()

      $login.loggedIn.INDIVIDUAL = true
    },
    onLogout: () => {
      // Delete tokens for company employee and app
      $token.setToken('COMPANY_APP_TOKEN', null)
      $token.setToken('COMPANY_EMPLOYEE_TOKEN', null)

      $login.loggedIn.INDIVIDUAL = false
    }
  })

  const getEmploymentToken = async (
    employment: Individual.Employment
  ): Promise<string> => {
    const { data } = await $http<Auth.ILoginResponse>('INDIVIDUAL_TOKEN', {
      url: `/employments/${employment.id}/token`,
      baseURL: apiBaseUrl.value,
      method: 'POST'
    })

    return data.token
  }

  const individual = reactive({
    ..._individual,
    employments,

    loadEmployments,
    getEmploymentToken
  })

  return {
    provide: {
      individual
    }
  }
})
