import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/app/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_Y6agwlnXZq from "/app/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa-icons.mjs";
import pwa_client_Eorgdr12jA from "/app/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import switch_locale_path_ssr_5csfIgkrBP from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import loader_hdpS77Ubg5 from "/app/plugins/loader.ts";
import token_NCWuir2H2e from "/app/plugins/token.ts";
import login_r3gbI5eVa9 from "/app/plugins/login.ts";
import company_j3fM3zO30U from "/app/plugins/company.ts";
import companyApp_8V8X0DIfmu from "/app/plugins/companyApp.ts";
import partnerApp_2z4vqP61Fh from "/app/plugins/partnerApp.ts";
import locale_PT4e7V2ngC from "/app/plugins/locale.ts";
import api_GFfDXud5Cr from "/app/plugins/api.ts";
import individual_K5kJv1Sqh1 from "/app/plugins/individual.ts";
import partner_Ud2h5iVwHM from "/app/plugins/partner.ts";
import partnerEmployee_7VM33GVaxK from "/app/plugins/partnerEmployee.ts";
import payianoEmployee_1D0nlmFElm from "/app/plugins/payianoEmployee.ts";
import gtag_client_Zw8EQXNVTz from "/app/plugins/gtag.client.ts";
import vite_hmr_E6vLuKPl5P from "/app/plugins/vite-hmr.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  pwa_icons_Y6agwlnXZq,
  pwa_client_Eorgdr12jA,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  loader_hdpS77Ubg5,
  token_NCWuir2H2e,
  login_r3gbI5eVa9,
  company_j3fM3zO30U,
  companyApp_8V8X0DIfmu,
  partnerApp_2z4vqP61Fh,
  locale_PT4e7V2ngC,
  api_GFfDXud5Cr,
  individual_K5kJv1Sqh1,
  partner_Ud2h5iVwHM,
  partnerEmployee_7VM33GVaxK,
  payianoEmployee_1D0nlmFElm,
  gtag_client_Zw8EQXNVTz,
  vite_hmr_E6vLuKPl5P
]