import VueGtag, { trackRouter } from 'vue-gtag-next'

// @see: https://nuxt.com/docs/guide/directory-structure/plugins#vue-plugins

// TODO: to be removed and use native implementation
export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()

  // TODO: to be replaced with normal loading, to not block content when it's loaded

  nuxtApp.vueApp.use(VueGtag, {
    isEnabled: config.public.env === 'production',
    disableScriptLoader: config.public.env !== 'production',
    property: {
      id: config.public.google.tagKey
    }
  })

  trackRouter(useRouter())
})
