import { computed } from 'vue'
import { ApiDomain } from '@/types'

export const useApiBaseUrl = (domain: ApiDomain) => {
  const config = useRuntimeConfig()

  const apiBaseUrl = computed(() =>
    config.public.isProduction
      ? config.public.sandbox.domains[domain]
      : config.public.local.domains[domain]
  )

  return apiBaseUrl
}
