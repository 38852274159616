import { useAuthService } from '@/composables'
import { AccessTokenType, ApiDomain, Partner } from '@/types'

export default defineNuxtPlugin(() => {
  const domain: ApiDomain = 'PARTNER'
  const tokenName: AccessTokenType = 'PARTNER_EMPLOYEE_TOKEN'

  const _partner = useAuthService<Partner.IPartner>({
    domain,
    tokenName,
    whoamiPath: '/partner/whoami',
    getUser: (data) => data.partner
  })

  const partner = reactive({
    ..._partner
  })

  return {
    provide: {
      partner
    }
  }
})
