import { MaybeRef } from '@payiano/ha-types'
import { IUseSeoMetaTagsProps } from '@/types'

export const useSeoMetaTags = (_props: MaybeRef<IUseSeoMetaTagsProps>) => {
  const props = computed(() => unref(_props))

  // Title need to be between 30 and 60 characters.
  const title = computed(() =>
    unref(props.value.title)
      .replace(/\s+/g, ' ')
      // Remove leading and trailing spaces.
      .replace(/^\s+|\s+$/g, '')
  )

  // Description need to be between 150 and 300 characters.
  const description = computed(() =>
    unref(props.value.description)
      .replace(/\s+/g, ' ')
      // Remove leading and trailing spaces.
      .replace(/^\s+|\s+$/g, '')
  )

  const keywords = computed(() =>
    [...new Set(unref(props.value.keywords))].join(', ')
  )

  useHead({
    title,
    meta: [
      { name: 'description', content: description },
      { name: 'keywords', content: keywords },
      // Facebook Open Graph meta tags',
      { property: 'og:title', content: title },
      { property: 'og:description', content: description },
      // Twitter meta tages',
      { name: 'twitter:title', content: title },
      { name: 'twitter:description', content: description }
    ]
  })
}
