import axios, { AxiosInstance, AxiosResponse } from 'axios'
import { $alerts } from '@payiano/ha-alerts'
import { AxiosPossibleError, Mixed, Nullable } from '@payiano/ha-types'
import { getMergedRequestConfigs, isObject, isString } from '@payiano/ha-utils'
import { AccessTokenType, ApiResponseStatusCode, AxiosConfig } from '@/types'

export default defineNuxtPlugin(() => {
  const { $token, $i18n, $login, $company, $companyApp, $partnerApp } =
    useNuxtApp()

  const api: AxiosInstance = axios.create({
    headers: {
      'Content-Type': 'application/json'
    }
  })

  api.interceptors.request.use((config) => {
    config.headers['Accept-Language'] = $i18n.locale.value

    return config
  })

  api.interceptors.response.use(
    (response) => response,
    (res: AxiosPossibleError) => {
      const { status, data, config: _config } = res?.response ?? {}
      const message = data?.message ?? data?.error ?? res?.message

      const config = _config as AxiosConfig
      const ignoredStatuses = config?.metadata?.ignore?.status ?? []

      if (
        !status ||
        !ignoredStatuses.includes(status as ApiResponseStatusCode)
      ) {
        if (
          [401, 403, 404, 422, 429].includes(status as number) &&
          !config?.metadata?.silent
        ) {
          let genericAlert = true

          if (status === 401) {
            const individualUrl = useApiBaseUrl('INDIVIDUAL')
            const companyUrl = useApiBaseUrl('COMPANY')
            const partnerUrl = useApiBaseUrl('PARTNER')
            const allyUrl = useApiBaseUrl('ALLY')

            genericAlert = false
            if (
              // If user tries to hit individual resources.
              config?.baseURL?.startsWith(individualUrl.value) ||
              // If user tries to hit company employee resources.
              (config?.baseURL?.startsWith(companyUrl.value) &&
                !$login.loggedIn.INDIVIDUAL &&
                config.metadata?.tokenName === 'COMPANY_EMPLOYEE_TOKEN')
            ) {
              $token.setTokens(null)

              $login.openModal('INDIVIDUAL')

              $alerts.error(
                'You should login first to your account to test the APIs with.'
              )
            } else if (config?.baseURL?.startsWith(companyUrl.value)) {
              if (config.metadata?.tokenName === 'COMPANY_EMPLOYEE_TOKEN') {
                $company.openModal()

                $alerts.error(
                  'Please select a company first to test the APIs with.'
                )
              } else if (!$login.loggedIn.INDIVIDUAL) {
                $login.openModal('INDIVIDUAL')

                $alerts.error(
                  'You should login first to your account and create an app for a company to test the APIs with.'
                )
              } else if (config.metadata?.tokenName === 'COMPANY_APP_TOKEN') {
                $companyApp.openModal()

                $alerts.error(
                  'Please select an app first to test the APIs with.'
                )
              }
            } else if (config?.baseURL?.startsWith(partnerUrl.value)) {
              genericAlert = false

              if (config.metadata?.tokenName === 'PARTNER_EMPLOYEE_TOKEN') {
                $login.openModal('PARTNER')

                $alerts.error(
                  'You should login to a partner account first to test the APIs with.'
                )
              } else if (!$login.loggedIn.PARTNER) {
                $login.openModal('PARTNER')

                $alerts.error(
                  'You should login to your partner account first and select an app to test the APIs with.'
                )
              } else if (config.metadata?.tokenName === 'PARTNER_APP_TOKEN') {
                $partnerApp.openModal()

                $alerts.error(
                  'Please select an app first to test the APIs with.'
                )
              }
            } else if (config?.baseURL?.startsWith(allyUrl.value)) {
              genericAlert = false

              if (config.metadata?.tokenName === 'PARTNER_APP_TOKEN') {
                if (!$login.loggedIn.PARTNER) {
                  $login.openModal('PARTNER')
                }

                if (config?.url?.includes('/basata')) {
                  $alerts.error({
                    message: {
                      html: 'Please ensure you are logged in <b>Basata</b> partner account and select an app to test the APIs with.'
                    }
                  })
                } else {
                  $alerts.error(
                    'Please ensure you are logged in the proper partner / ally account and select an app to test the APIs with.'
                  )
                }
              }
            }
          }

          if (genericAlert) {
            $alerts.error(message)
          }
        } else if (status === 500) {
          $alerts.error($i18n.t('errors.unexpectedError'))
        } else if (res?.code === 'ERR_NETWORK') {
          $alerts.error($i18n.t('errors.networkError'))
        }
      }

      return Promise.reject(res)
    }
  )

  const httpFn =
    <T = Mixed>(
      tokenName?: Nullable<AccessTokenType> | AxiosConfig,
      _config?: AxiosConfig
    ) =>
    (config?: AxiosConfig): Promise<AxiosResponse<T>> => {
      const __config = (isObject(tokenName) ? tokenName : {}) as AxiosConfig

      const token =
        tokenName && isString(tokenName)
          ? $token.getFullToken(tokenName as AccessTokenType)
          : null

      return api<T>(
        getMergedRequestConfigs(
          {
            headers: {
              Authorization: token
            },
            metadata: {
              tokenName: tokenName as Nullable<AccessTokenType>
            }
          },
          config,
          __config,
          _config
        )
      )
    }

  const http = <T = Mixed>(
    tokenName?: Nullable<AccessTokenType> | AxiosConfig,
    _config?: AxiosConfig
  ) => httpFn<T>(tokenName, _config)()

  return {
    provide: { api, http, httpFn }
  }
})
