export default defineNuxtPlugin((nuxtApp) => {
  if (import.meta.client) {
    const el = document.createElement('div')
    const loader = document.createElement('img')

    loader.style.height = '200px'
    loader.src = '/images/payiano-blue-animated.gif'

    el.style.top = '0px'
    el.style.left = '0px'
    el.style.width = '100%'
    el.style.height = '100%'
    el.style.display = 'flex'
    el.style.zIndex = '100000'
    el.style.position = 'fixed'
    el.style.alignItems = 'center'
    el.style.justifyContent = 'center'
    el.style.backgroundColor = '#fff'

    el.appendChild(loader)

    document.body.appendChild(el)

    nuxtApp.hook('page:finish', () => el?.remove())
  }
})
