import { useAuthService } from '@/composables'
import { AccessTokenType, ApiDomain, Partner } from '@/types'

export default defineNuxtPlugin(() => {
  const domain: ApiDomain = 'PARTNER'
  const tokenName: AccessTokenType = 'PARTNER_EMPLOYEE_TOKEN'

  const { $login, $token, $partner } = useNuxtApp()

  const _partnerEmployee = useAuthService<Partner.IEmployee>({
    domain,
    tokenName,
    whoamiPath: '/employee/whoami',
    getUser: (data) => data.employee,
    onLoadProfile: () => {
      $login.loggedIn.PARTNER = true
    },
    onLogin: async () => {
      await $partner.loadProfileInfo()
    },
    onLogout: () => {
      // Delete tokens for partner employee and app
      $token.setToken(tokenName, null)
      $token.setToken('PARTNER_APP_TOKEN', null)

      $login.loggedIn.PARTNER = false
      $partner.setLoggedOut()
    }
  })

  const partnerEmployee = reactive({
    ..._partnerEmployee
  })

  return {
    provide: {
      partnerEmployee
    }
  }
})
