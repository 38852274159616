import validate from "/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import trailing_45slash_45global from "/app/middleware/trailingSlash.global.ts";
import user_45info_45global from "/app/middleware/userInfo.global.ts";
import manifest_45route_45rule from "/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  trailing_45slash_45global,
  user_45info_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "payiano-employee": () => import("/app/middleware/payianoEmployee.ts")
}