import { Auth } from '@/types'

export default defineNuxtPlugin(() => {
  const showModal = ref(false)
  const accountType = ref<Auth.Account>()
  const loggedIn = ref<Partial<Record<Auth.Account, boolean>>>({})

  const login = reactive({
    loggedIn,
    showModal,
    accountType,
    openModal: (type: Auth.Account) => {
      accountType.value = type
      showModal.value = true
    },
    closeModal: () => (showModal.value = false)
  })

  return {
    provide: { login }
  }
})
