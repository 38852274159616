/**
 * Vite-HMR Plugin for Vue Router Meta Key in Development Mode
 *
 * This plugin is designed to enhance the development experience in Nuxt.js projects
 * by ensuring that the vue page is automatically updated after Vite performs a
 * Hot Module Replacement (HMR) update. It specifically targets the `meta.key`
 * attribute in the Vue Router, incrementing it to force a refresh of the vue page.
 *
 * Features:
 * - Automatically updates the vue page after HMR updates.
 * - Active only in development mode.
 * - Increments the `meta.key` attribute in the Vue Router to trigger a re-render.
 * - Preserves the scroll position after the update.
 *
 * Usage:
 * - Add this plugin to your Nuxt.js project to ensure the vue page is updated after any
 *   HMR updates during development.
 *
 * Benefits:
 * - Improves development workflow by automatically reflecting changes.
 * - Seamlessly integrates with Nuxt.js and Vite.
 * - Does not affect production performance.
 *
 * Rules:
 * - Only works in development mode.
 * - Requires to add :key="$route.meta.key" to the app wrapper.
 */

import { useRoute } from 'vue-router'

export default defineNuxtPlugin(() => {
  const route = useRoute()
  const config = useRuntimeConfig()
  const isProduction = config.public.env === 'production'

  if (import.meta.hot && !isProduction) {
    import.meta.hot.on('vite:afterUpdate', () => {
      const scrollPosition = { x: window.scrollX, y: window.scrollY }

      const metaKey = Number(route.meta.key)

      if (!isNaN(metaKey)) {
        route.meta.key = String(metaKey + 1)
      } else {
        route.meta.key = '1'
      }

      setTimeout(() => {
        if (
          scrollPosition.x !== window.scrollX ||
          scrollPosition.y !== window.scrollY
        ) {
          window.scrollTo(scrollPosition.x, scrollPosition.y)
        }
      }, 150)
    })
  }
})
