import { useAuthService } from '@/composables'
import { AccessTokenType, ApiDomain, Payiano } from '@/types'

export default defineNuxtPlugin(() => {
  const domain: ApiDomain = 'ADMIN'
  const tokenName: AccessTokenType = 'PAYIANO_EMPLOYEE_TOKEN'

  const { $login, $token } = useNuxtApp()

  const _payianoEmployee = useAuthService<Payiano.User>({
    domain,
    tokenName,
    whoamiPath: '/employee/whoami',
    includes: {
      employee: {
        //
      }
    },
    getUser: (data) => data.employee,
    onLoadProfile: () => {
      $login.loggedIn.ADMIN = true
    },
    onLogout: () => {
      // Delete tokens for payiano employee
      $token.setToken(tokenName, null)

      $login.loggedIn.ADMIN = false
    }
  })

  const payianoEmployee = reactive({
    ..._payianoEmployee
  })

  return {
    provide: {
      payianoEmployee
    }
  }
})
