<template>
  <div class="flex items-center justify-center bg-slate-50 h-screen">
    <section class="mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8">
      <div class="text-center mb-4 py-6">
        <nuxt-link
          to="/"
          aria-label="Home page"
          @click.prevent.stop="() => clearError({ redirect: '/' })"
        >
          <HaIcon :icon="PayianoEnglishSideBlueIcon" height="50" />
        </nuxt-link>
      </div>

      <article
        class="py-4 px-6 rounded-xl bg-white text-center shadow-sm md:shadow-md"
      >
        <div class="p-6 md:p-10">
          <h1 class="py-4 text-3xl lg:text-5xl font-extrabold text-gray-800">
            {{ t('errorPage.title') }}
          </h1>

          <p class="text-4xl mb-8">{{ error?.statusCode }}</p>

          <p class="text-base text-gray-800">
            {{ t('errorPage.missingLink') }}
          </p>

          <p class="py-2 mb-8 text-base text-gray-800">
            {{ t('errorPage.description') }}
          </p>

          <div class="flex justify-center">
            <HaButton
              pill
              to="/"
              size="md"
              color="secondary"
              component="RouterLink"
              @click.prevent.stop="() => clearError({ redirect: '/' })"
            >
              {{ t('errorPage.goToHome') }}
            </HaButton>
          </div>
        </div>
      </article>
    </section>
  </div>
</template>

<script lang="ts" setup>
import { PayianoEnglishSideBlueIcon } from '@payiano/hc-icons'
import { HaButton, HaIcon } from '@payiano/ha-atoms'

const { t } = useI18n()

interface IError {
  url: string
  statusCode: number
  statusMessage: string
  message: string
  description: string
  data?: any
}

defineProps<{
  error?: IError
}>()
</script>
