import { Nullable } from '@payiano/ha-types'
import { isObject } from '@payiano/ha-utils'
import { AccessTokenType, TokenInstance, Tokens } from '@/types'

export default defineNuxtPlugin(() => {
  const cookie = useCookie<Nullable<Tokens>>('auth_tokens')

  const _token = ref<Tokens>(
    (isObject(cookie.value) ? cookie.value : {}) as Tokens
  )

  const token = reactive<TokenInstance>({
    getToken: (name: AccessTokenType): Nullable<string> => _token.value[name],
    getFullToken: (name: AccessTokenType): Nullable<string> =>
      _token.value[name] ? ['Bearer', _token.value[name]].join(' ') : null,
    setToken: (name: AccessTokenType, value: Nullable<string>): void => {
      _token.value = {
        ..._token.value,
        [name]: value
      }

      cookie.value = _token.value
    },
    setTokens: (tokens: Nullable<Tokens>): void => {
      _token.value = (tokens ?? {}) as Tokens
      cookie.value = tokens
    }
  })

  return {
    provide: { token }
  }
})
