import { Nullable } from '@payiano/ha-types'

export default defineNuxtPlugin(() => {
  const showModal = ref(false)
  const selectedId = useCookie<Nullable<string>>(
    'developer_selected_partner_app_id'
  )

  const partnerApp = reactive({
    showModal,
    selectedId,

    openModal: () => (showModal.value = true),
    closeModal: () => (showModal.value = false)
  })

  return {
    provide: { partnerApp }
  }
})
