import { ref, Ref } from 'vue'
import { Nullable } from '@payiano/ha-types'
import { checkCallback } from '@payiano/ha-utils'
import { Auth } from '@/types'

export const useAuthService = <T extends object>({
  domain,
  tokenName,
  whoamiPath,
  includes,
  getUser,
  onLoadProfile,
  onLogin,
  onLogout
}: Auth.IServiceProps<T>): Auth.IService<T> => {
  const { $http, $token } = useNuxtApp()
  const apiBaseUrl = useApiBaseUrl(domain)

  const user = ref({}) as Ref<T>
  const loggedIn = ref(false)
  const loading = ref(false)
  const loaded = ref(false)

  const setUser = (payload: Partial<T>) => {
    user.value = {
      ...user.value,
      ...payload
    }
  }

  const setToken = (value: Nullable<string>) =>
    $token.setToken(tokenName, value)

  const getToken = (): Nullable<string> => $token.getToken(tokenName)

  const loadProfileInfo = async (): Promise<void> => {
    loading.value = true

    try {
      const { data } = await $http<{ user: T }>(tokenName, {
        url: whoamiPath,
        baseURL: apiBaseUrl.value,
        params: {
          includes
        },
        metadata: {
          silent: true
        }
      })

      setUser(checkCallback(getUser, [data]) ?? data.user)

      await checkCallback(onLoadProfile, [data])

      loggedIn.value = true
      loaded.value = true
      loading.value = false
    } catch (e) {
      setToken(null)
    } finally {
      loading.value = false
    }
  }

  const setLoggedOut = (): void => {
    setToken(null)

    user.value = {} as T

    loggedIn.value = false
    loaded.value = false
  }

  const logout = async (): Promise<void> => {
    try {
      await $http(tokenName, {
        url: '/logout',
        baseURL: apiBaseUrl.value,
        method: 'POST',
        metadata: {
          silent: true
        }
      })
    } catch (e) {
      //
    } finally {
      setLoggedOut()

      await checkCallback(onLogout)
    }
  }

  const login = async (token: string): Promise<void> => {
    setToken(token)

    await loadProfileInfo()

    await checkCallback(onLogin)
  }

  return {
    user,
    loggedIn,
    loading,
    loaded,

    setUser,
    setToken,
    getToken,
    loadProfileInfo,
    setLoggedOut,
    login,
    logout
  }
}
